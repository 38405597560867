import { useEffect, useState } from "react";
import dummyApi from "./dummyApiLoe";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import DataTable, { createTheme } from "react-data-table-component";
import PlusIcon from "../../assets/plus.png";
import Button from "../../components/button/button";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ConvertDate } from "../employeeManagement/utils/date-converter";
import ReviewModal from "../../components/createInternshipModule/reviewModal";
import Eye from "../../assets/Group 1449.png";
import Download from "../../assets/Download-Icon.png";
import Tick from "../../assets/checkmark.png";
import BulkSuccessErrorModal from "../../components/bulkStudentUpload/bulkSuccessErrorModal";
import AlertModal from "../../components/singleStudentUpload/alertModal";
import { LOEApplication } from "./loeStatic";
import ViewLOE from "../LoeApplication/viewLOE";
import {
  getInternshipModuleList,
  getLOEHistory,
  getLOEListSubmission,
  getNonMigratedIntern,
  postLOESubmission,
} from "../../services/apiCalls";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import {
  DataTableWrapper,
  Heading,
  LoaderBackdrop,
  LoaderWrapper,
} from "../../pages/employeeManagement/commonStyles";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ReactComponent as RightIcon } from "../../assets/svg/check_icon.svg";
import { ReactComponent as PendingPayment } from "../../assets/svg/pending-payment.svg";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross_icon.svg";
import { ReactComponent as Pending } from "../../assets/icons/svg/pendingIcon.svg";
import { ReactComponent as Remarks } from "../../assets/icons/svg/remarks.svg";
import UploadPlusIcon from "../../assets/uploader.png";
import CreateInternshipModuleModal from "../../components/createInternshipModule/createInternshipModal";
import InternCard from "../../components/InternshipCard/internCard";
import { Info } from "@mui/icons-material";
import { internshipModule } from "./staticContentInternship";

const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const HistoryWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  border-radius: 50px;
  margin-top: 20px;
`;
const HistoryHeaderWrapper = styled.div`
  margin-left: 20px;
  padding: 10px;
  margin-top: -10px;
`;
const HistoryHeader = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #d04c8d;
`;
const HistoryTableLabel1 = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  margin-top: 5px;
`;
const HistoryTableLabel = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  margin-top: 5px;
  margin-left: -20px;
`;
const HistoryHeading = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  margin-top: 5px;
`;
const HistoryTableLabel2 = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  margin-top: 5px;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 30px;
  padding: 5px;
  background-color: #d04c8d;
  height: 50px;
  width: 400px;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
  > img {
    width: 50px;
    height: 50px;
    margin-top: -4px;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonContent = styled.div`
  display: flex; /* Display img and ButtonText in a row */
  border-radius: 30px;
  height: 50px;
  width: 400px;
  text-align: center;
  cursor: pointer;
  > img {
    width: 50px;
    height: 50px;
    margin-top: -4px;
  }
`;

const ButtonText = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: white;
`;
const ButtonText2 = styled.h4`
  margin-top: -25px;
  margin-left: 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  display: block;
  line-height: 10px;
  color: white;
`;
const ApplicationHeaderWrapper = styled.div`
  margin-left: 20px;
  padding: 10px;
  margin-top: -10px;
  display: flex;
  justify-content: ${(props) => (props.isRight ? "flex-end" : "space-between")};
  width: auto;
`;

const ApplicationHeaderWrapper2 = styled.div`
  margin-left: 20px;
  padding: 10px;
  margin-top: -10px;
  display: flex;
  gap: 20px;
  width: auto;
`;
const ApplicationHeader = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #d04c8d;
`;
const CusButton = styled.button`
  width: ${(props) => props.width};
  background: ${(props) =>
    props.secondary
      ? "transparent"
      : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
  border-radius: 10px;
  outline: none;
  border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
  border-style: ${(props) => (props.secondary ? "dotted" : "none")};
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
  color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
  padding: 13px 0px;
  margin: ${(props) => (props.margin ? props.margin : "15px 0")};
  // min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
  width: 100px;
  cursor: pointer;
  &:disabled {
    background: #d04c8d;
    color: #ccc;
    cursor: no-drop;
  }
  &:hover:not([disabled]) {
    color: ${Colors.light};
    background: ${(props) =>
      props.secondary
        ? Colors.pink
        : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
  }
`;
const ModalHeaderTextFour = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-align: left;
  padding-left: 35px;
  padding-right: 150px;
  padding-top: 25px;
  color: #202020;
  margin-top: 3px;
`;
const ModalHeaderTextFive = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-align: left;
  padding-right: 150px;
  // padding-top: 10px;
  color: #202020;
  margin-top: 3px;
`;
const HistoryHeaderLabel = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-align: left;
  padding-right: 150px;
  color: #202020;
`;

const ValidityText = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  color: #d04c8d;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  max-width: 900px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const ButtonWrapperModal = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 50px;
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const IconSection = styled.img`
  margin-right: 5px;
  width: 30px;
  height: 30px;
  filter: ${(props) => (props.hovered ? "invert(1)" : "none")};
  transition: filter 0.2s ease-in-out;
`;
export const DataTableWrapper1 = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 3rem;
  .rdt_TableHead .rdt_TableCol_Sortable div {
    font-size: medium;
    font-weight: 600;
  }
  .custom-selector {
    div[id*="option"] {
      &:hover {
        background-color: #f3f0f0 !important;
      }
    }
  }
  .rdt_TableHead {
    border-radius: 34px;
    height: 83px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-bottom: 15px;
    .rdt_TableHeadRow {
      // margin: 0 -0.5rem;
    }

    .rdt_TableCol_Sortable,
    rdt_TableCol {
      font-family: General Sans;
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 108%;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }
  .rdt_TableBody {
    border-radius: 0px 0px 34px 34px;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    .rdt_TableRow {
      margin-left: 31px;
      &:hover {
        background-color: #ffe9f4;
      }
    }
  }
  @media (max-width: 500px) {
    position: relative;
    margin-top: 64px;
    .rdt_TableBody {
      border-radius: 0px 0px 34px 34px;
      // overflow: scroll;

      .rdt_TableRow {
        cursor: pointer;
        margin-left: 33px;
        &:hover {
          background-color: #ffe9f4;
        }
      }
    }
  }
  @media (min-width: 821px) and (max-width: 1024px) {
    margin-top: 90px;
  }
  @media (min-width: 768px) and (max-width: 1100px) {
    .rdt_TableHeadRow {
      margin: 0 1.5rem;
    }
    .rdt_TableBody {
      border-radius: 0px 0px 34px 34px;
      // overflow: scroll;
      position: relative;
      align-items: self-end .rdt_TableRow {
        cursor: pointer;
        &:hover {
          background-color: #ffe9f4;
        }
      }
    }
  }
`;
const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const CustomHeading = styled(Heading)`
  color: #acacac;
`;
const TableWrapper = styled.table`
  width: 100%;
  text-align: center;
  padding: 10px;
  border-collapse: separate;
  border-spacing: 0 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin-top: -20px;
  tr {
    border-bottom: 3px solid #ccc;
  }

  td {
    padding: 10px;
  }
`;
const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: center;
`;
const TableWrapper1 = styled.table`
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 10px;
  border-collapse: separate;
  border-spacing: 0 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin-top: -20px;
  tr {
    border-bottom: 3px solid #ccc;
  }

  td {
    padding: 10px;
  }
`;

const LOEAppWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  border-radius: 50px;
  margin-top: 40px;
`;
const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  > button {
    width: 300px;
  }
`;

const columnStylingBigger = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "200px",
  color: "#202020",
  margin: " 1.5rem 0",
  paddingTop: "10px",
  paddingBottom: "20px",

  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};

const columnStylingSubmittedApplication = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "200px",
  color: "#202020",
  margin: " 1.5rem 0",
  paddingTop: "50px",
  paddingBottom: "50px",

  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
}
const columnStyling = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  minWidth: "92px",
  color: "#202020",
  margin: " 0.5rem 1.5rem 0.5rem 0",
  justifyContent: "center",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};
const columnStyling2 = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  minWidth: "92px",
  color: "#202020",
  marginLeft: "-20px",
  justifyContent: "center",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};
const customStyles = {
  style: {
    fontSize: "16px",
    width: "1px",
    overflow: "scroll",
  },
};

const ReviewButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
`;
const Images = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  top: 2px;
  cursor: pointer;
`;
const Images2 = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  top: 2px;
`;
const Img2 = styled.img`
  position: relative;
  width: 30px;
  height: 30px;
  top: 2px;
  color: #ffffff;
`;
export const ModalHeaderTextThree = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  color: #202020;
  margin-top: 29px;
`;
export const ModalHeaderError = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  color: red;
  margin-top: 29px;
`;
export default function LOEApplicationLanding({ setUnAuth }) {
  const [internships, setInternships] = useState([]);
  const [reviewModal, setReviewModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmData, setConfirmData] = useState([]);
  const [nonMigratedData, setDataMigrationDataIntern] = useState();
  const [hideDiv, setHidDiv] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const companyData = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [loeHistory, setLoeHistory] = useState([]);
  const [loeSubmissionInternshipList, setLoeSubmissionInternshipList] =
    useState([]);
  const [loeApplications, setLoeApplications] = useState({});
  const [submittedLoeApplicationList, setSubmittedLoeApplicationList] =
    useState([]);
  const [loeSubmissionId, setLoeSumissionId] = useState({
    loe_application_id: null,
    internship_ids: null,
  });
  const [selectedAdvertised, setSelectedAdvertised] = useState([]);
  const [selectedGeneral, setSelectedGeneral] = useState([]);
  const [selectedDirectHiring, setSelectedDirectHirirng] = useState([]);
  const [selectedInternshipIds, setSelectedInternshipIds] = useState([]);
  const [isModuleCreated, setIsModuleCreated] = useState(false);
  const [isModuleApproved, setIsModuleApproved] = useState(false);
  const [internshipModuleData, setInternshipModuleData] = useState([]);
  const [showSIP, setShowSIP] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [getYear, setYear] = useState("");
  const userRole = useSelector((state) => state.company.currentOrgData);
  const currentYear = new Date().getFullYear();
  const [internshipModuleId, createdInternshipModuleId] = useState(null);
  const [canSubmit, setCanSubmit] = useState("");
  const [message, setMessage] = useState("");

  let backgroundColor = "#FFFFFF";
  const getLoeHistoryList = async () => {
    const { data } = await getLOEHistory(org_code);
    if (data) {
      setLoeHistory(data);
    }
  };
  const onNavigateForm = () => {
    if (userRole?.user_details.internship_modify !== "Y") {
      return setUnAuth(true);
    }
    navigate("/loe/internship-module-eligibility-check", {
      state: { origin: "sip" },
    });
    // navigate("/internships/internship-module",  { state: { data: eligibleData , from: "loe"} } );
  };

  const getNonMigratedInternFunc = async () => {
    const { data } = await getNonMigratedIntern(companyData.id);
    console.log("gettedDaata", data);
    if (data) {
      setDataMigrationDataIntern(data.data);
    }
  };
  console.log("companyData", companyData);
  const getLoeSubmission = async () => {
    const { data } = await getLOEListSubmission(org_code);
    if (data) {
      setLoeSubmissionInternshipList(data?.internships);
      setLoeApplications(data?.application);
      setSubmittedLoeApplicationList(data?.submitted_internships);
      setCanSubmit(data.can_submit_application);
      setMessage(data.message);
      setShowBtn(true);

      setLoeSumissionId({
        loe_application_id: data?.application?.id,
        internship_ids: selectedInternshipIds,
      });
    }
  };
  const getInternshipModuleData = async () => {
    const { data } = await getInternshipModuleList(org_code);
    console.log("internshipModuleData", data);

    if (data != undefined) {
      const createdYears = data.map((item) => item.created_at);
      const status = data.map((item) => item.status);
      const years = createdYears.map((created_at) =>
        new Date(created_at).getFullYear()
      );
      //let date = data[0].created_at.substring(0,4);

      // const yearDate  = Date(Date.parse(date));
      // setCreatedYear(years);
      if (status.includes("approved")) {
        setIsModuleApproved(true);
      }
      if (years.includes(currentYear)) {
        // console.log("yearNow",years, date)
        // const getLatestModuleData = data.filter((item) => parseInt(item.created_at.substring(0,4))  === currentYear);
        // console.log("currentModule",getLatestModuleData);
        setIsModuleCreated(true);
        setYear(years);
      }
      setInternshipModuleData(data);
      const idInternshipModule = data.map((item) => item.id);
      createdInternshipModuleId(idInternshipModule);
      // setLoading(false);
    }
  };
  //   useEffect( async ()=>{

  //   // else{
  //   //   setLoading(false);
  //   // }

  // },[org_code])
  useEffect(() => {
    // dummyApi.getInterships()
    //   .then((data) => {
    //     setInternships(data.internshipModule);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching internships:", error);
    //   });
    //   setHidDiv(true);
    getLoeHistoryList();
    getLoeSubmission();
    getInternshipModuleData();
  }, [org_code]);

  useEffect(() => {
    if (companyData.id) {
      getNonMigratedInternFunc();
    }
  }, [companyData]);
  const onShow = () => {
    setShowSIP(true);
  };
  const onClose = () => {
    setShowSIP(false);
  };
  console.log(loeSubmissionId.loe_application_id, "LOE Submission ID");
  console.log(submittedLoeApplicationList, "AFTER SUBMIT LOE");
  console.log(loeSubmissionId.internship_ids, "SELECTED INTERNSHIPS");
  const [isDisabled, setIsDisabled] = useState(true);

  const isInternshipSelected = () => {
    return (
      selectedAdvertised.length > 0 ||
      // selectedGeneral.length > 0 ||
      selectedDirectHiring.length > 0
      // Add more conditions if you have additional intern_types
    );
  };

  useEffect(() => {
    //setIsDisabled(!isInternshipSelected());
  }, [selectedAdvertised, selectedDirectHiring]);

  const handleRowSelection = (row) => {
    const { intern_type } = row; // Get the intern_type value from the row

    switch (intern_type) {
      case "Advertise":
      case "sip":
        handleAdvertisedSelection(row);
        break;
      case "general":
      case "General Internship":
        handleGeneralSelection(row);
        break;
      case "Direct Hiring":
        handleDirectHiringSelection(row);
        break;
      default:
    }
  };

  const handleAdvertisedSelection = (row) => {
    const updatedAdvertised = selectedAdvertised.includes(row)
      ? selectedAdvertised.filter((selectedRow) => selectedRow !== row)
      : [...selectedAdvertised, row];

    setSelectedAdvertised(updatedAdvertised);

    // Update the common selectedData state with all selected rows
    const combinedSelectedData = [
      ...selectedData.filter(
        (item) => item.intern_type !== "Advertise" && item.intern_type !== "sip"
      ),
      ...updatedAdvertised,
    ];
    setSelectedData(combinedSelectedData);

    const updatedInternshipIds = combinedSelectedData.map(
      (selectedRow) => selectedRow.id
    ); // Adjust this based on your data structure
    setSelectedInternshipIds(updatedInternshipIds);
  };

  // Function to handle General intern_type selection
  const handleGeneralSelection = (row) => {
    const updatedGeneral = selectedGeneral.includes(row)
      ? selectedGeneral.filter((selectedRow) => selectedRow !== row)
      : [...selectedGeneral, row];

    setSelectedGeneral(updatedGeneral);

    // Update the common selectedData state with all selected rows
    const combinedSelectedData = [
      ...selectedData.filter(
        (item) =>
          item.intern_type !== "general" &&
          item.intern_type !== "General Internship"
      ),
      ...updatedGeneral,
    ];
    setSelectedData(combinedSelectedData);

    const updatedInternshipIds = combinedSelectedData.map(
      (selectedRow) => selectedRow.id
    ); // Adjust this based on your data structure
    setSelectedInternshipIds(updatedInternshipIds);
  };

  // Function to handle Direct Hiring intern_type selection
  const handleDirectHiringSelection = (row) => {
    const updatedDirectHiring = selectedDirectHiring.includes(row)
      ? selectedDirectHiring.filter((selectedRow) => selectedRow !== row)
      : [...selectedDirectHiring, row];

    setSelectedDirectHirirng(updatedDirectHiring);

    // Update the common selectedData state with all selected rows
    const combinedSelectedData = [
      ...selectedData.filter((item) => item.intern_type !== "Direct Hiring"),
      ...updatedDirectHiring,
    ];
    setSelectedData(combinedSelectedData);

    const updatedInternshipIds = combinedSelectedData.map(
      (selectedRow) => selectedRow.id
    ); // Adjust this based on your data structure
    setSelectedInternshipIds(updatedInternshipIds);
  };

  //handle onclick funtion when user click Add MySIP direct Hiring
  const handleAddSIP = () => {
    console.log(internshipModuleData, "Internship Module Data ");
    if (isModuleCreated && isModuleApproved) {
      //setShowModal(true)
      navigate(
        `/internships/createinternship/selectinternshiptype?selectedIds=${internshipModuleId}`
      );
    } else if (isModuleCreated && !isModuleApproved) {
      setShowModal(true);
    } else {
      navigate("/loe/internship-module-eligibility-check", {
        state: { origin: "sip" },
      });
    }
    // if(internshipModuleData.id ==="" ){
    //   navigate("/internships/createinternship/selectinternshiptype" , { state: { origin: 'sip' } });

    // }else{
    //   navigate("/loe/internship-module-eligibility-check" , { state: { origin: 'sip' } });
    // }
  };
  const getAdminStatusName = (status) => {
    switch (status) {
      case 0:
        return "Pending Approval";
      case 1:
        return "Approved";
      case 2:
        return "Review Sent";
      case 3:
        return "Pending Payment";
    }
  };

  const getCompleStatusName = (status) => {
    switch (status) {
      case 0:
        return "Pending Submission";
      case 1:
        return "Completion form submitted";
    }
  };
  const onViewSipApplication = (row) => {
    const internId = row.id;
    navigate(`/loe-application/view-internship?id=${internId}`);
  };
  const onCompleteMigration = () => {
    // navigate('/loe-application/select-interns');
    if (isModuleCreated === true) {
      navigate("/loe-application/loe-internship-module");
    } else {
      navigate("/loe-application/loe-internship-module");
    }
  };

  const [loeYear, setLoeYear] = useState();
  const [selectedCard, setSelectedCard] = useState([]);

  const handleCardClick = (cardId) => {
    if (selectedCard.includes(cardId)) {
      setSelectedCard(selectedCard.filter((id) => id !== cardId));
    } else {
      setSelectedCard([...selectedCard, cardId]);
    }
  };
  const onOpen = () => {
    if (selectedAdvertised.length === 0 && selectedDirectHiring.length === 0) {
      toast.error("Please select atleast one internship for LOE Submission.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setReviewModal(true);
    }
  };
  console.log("SELECTED YEAR", loeYear);
  const openConfirm = () => {
    setConfirmModal(true);
  };
  const handleSubmit = async () => {
    const { data } = await postLOESubmission(org_code, {
      loe_application_id: loeSubmissionId.loe_application_id,
      internship_ids: selectedInternshipIds,
    });
    if (data) {
      toast.success(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getLoeSubmission();
    } else {
      toast.error(
        "Failed to Submit Letter of Endorsement Application",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    setConfirmData(selectedData);
    setConfirmModal(false);
    setReviewModal(false);

    // Update the status of selected internships to "submit"
    const updatedInternships = internships.map((internship) => {
      if (selectedData.includes(internship)) {
        return { ...internship, status: "submit" };
      }
      return internship;
    });

    setInternships(updatedInternships);
    setHidDiv(false);
  };

  const getInternTypeName = (intern_type) => {
    switch (intern_type) {
      case "Advertise":
      case "advertise":
      case "sip":
        return "MySIP Advertised";

      case "Direct Hiring":
        return "MySIP Direct Hiring";
    }
  };

  const getDirectHiringType = (type) => {
    switch (type) {
      case "on-going":
        return "On-Going";

      case "completed":
        return "Completed";
    }
  };
  //columns for LOE history data table
  const historyColumn = [
    {
      // name: 'Name',
      selector: "download_icon",
      width: "50px",
      cell: (row) => {
        return (
          <div
            onClick={() => {
              onViewInternship();
              setLoeYear(row.year);
              setLoeFile(row.file);
            }}
          >
            <Images src={Download} alt="img" data-tip={"Download LOE"} />
            <ReactTooltip effect="solid" className="tooltip-custom-width" />
          </div>
        );
      },
    },
    {
      //name: 'Internship Type',
      selector: "internship_module_title",
      width: "150px",

      cell: (row) => {
        return (
          <>
            <HistoryTableLabel1>LOE {row.year}</HistoryTableLabel1>
          </>
        );
      },
    },
    {
      name: "MySIP Advertised Internships",
      style: columnStylingBigger,
      left: "30px",
      width: "250px",
      cell: (row) => {
        return (
          <>
            <HistoryTableLabel>
              {row.total_advertised_internships}
            </HistoryTableLabel>
          </>
        );
      },
    },
    {
      name: "MySIP Direct Hiring Internships",
      style: columnStylingBigger,
      //center: true,
      width: "250px",
      cell: (row) => {
        return (
          <>
            <HistoryTableLabel>
              {row.total_directhiring_internships}
            </HistoryTableLabel>
          </>
        );
      },
    },
    {
      name: "Total Interns",
      style: columnStylingBigger,
      //center: true,
      width: "200px",
      cell: (row) => {
        return (
          <>
            <HistoryTableLabel>{row.total_interns}</HistoryTableLabel>
          </>
        );
      },
    },
    {
      name: "Validity Start Date",
      style: columnStylingBigger,
      center: true,
      width: "200px",
      cell: (row) => {
        return (
          <>
            <HistoryTableLabel>
              {ConvertDate(row.validity_startdate, "dd/mm/yyyy")}
            </HistoryTableLabel>
          </>
        );
      },
    },
    {
      name: "Validity End Date",
      style: columnStylingBigger,
      center: true,
      width: "200px",
      cell: (row) => {
        return (
          <>
            <HistoryTableLabel>
              {ConvertDate(row.validity_enddate, "dd/mm/yyyy")}
            </HistoryTableLabel>
          </>
        );
      },
    },
  ];
  // columsn for LOE application data table
  const columnApplication = [
    {
      selector: "checkbox",
      width: "50px",
      cell: (row) => (
        <>
          <input
            style={{ height: "20px", width: "20px", cursor: "pointer" }}
            type="checkbox"
            checked={row.selected} // You need to handle this value in your data
            onChange={() => handleRowSelection(row)}
            disabled={
              canSubmit === 1 &&
              (row.completion_form_submitted === 0 ||
                row.approval_status === 0 ||
                row.eligible_interns === 0)
            }
            data-tip={
              canSubmit === 1 &&
              row.completion_form_submitted === 0 &&
              row.approval_status === 0
                ? "You will only be able to submit this internship once it has been completed and approved by the admin. If this internship is completed, please proceed with filling the Completion of Internship form."
                : canSubmit === 1 && row.adminApproval === 0
                ? "You will only be able to submit this internship once it has been reviewed and approved by the admin. You will receive an email once admin has reviewed the internship"
                : canSubmit === 1 && row.eligible_interns === 0
                ? "This internship has no eligible interns."
                : ""
            }
          />
          <ReactTooltip effect="solid" className="tooltip-custom-width" />
        </>
      ),
    },
    {
      selector: (row) => row.title + row.intern_type,
      sortable: true,
      style: columnStylingBigger,
      center: true,
      width: "250px",
      cell: (row, index) => {
        return (
          <>
            <Row
              style={{
                left: "0",
              }}
              // onClick={() => onRowClicked(row)}
            >
              <Col lg="">
                <span
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "0px",
                    color: "#D04C8D",
                    fontSize: "20px",
                  }}
                >
                  {row.title}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                {row?.intern_type === "Direct Hiring" ? (
                  <span>
                    {getInternTypeName(row.intern_type) +
                      " (" +
                      getDirectHiringType(row.direct_hiring_type) +
                      ")"}
                  </span>
                ) : (
                  <span>{getInternTypeName(row.intern_type)}</span>
                )}
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: "Start Date",
      selector: (row) =>
        ["-", "", null].includes(row.exp_start_date)
          ? "-"
          : ConvertDate(row.exp_start_date, "dd/mm/yyyy"),
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "End Date",
      selector: (row) =>
        ["-", "", null].includes(row.exp_end_date)
          ? "-"
          : ConvertDate(row.exp_end_date, "dd/mm/yyyy"),
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "Eligible Interns",
      selector: (row) => {
        return `${row.eligible_interns} / ${row.total_interns}`;
      },
      sortable: true,
      center: true,
      style: columnStyling,
      width: "250px",
    },
    {
      name: "Internship Evaluation",
      selector: (row) => {
        return `${row.completed_evaluations} / ${row.total_interns}`;
      },
      sortable: true,
      center: true,
      style: columnStyling2,
    },
    {
      name: "Completion of Internship",
      selector: (row) => row.completionOfInternship,
      sortable: true,
      style: columnStyling,
      cell: (row) => {
        const tooltipId = `completion-tooltip-${row.completion_form_submitted}`;
        const tooltipMessage = getCompleStatusName(
          row.completion_form_submitted
        );

        return (
          <div>
            {row.completion_form_submitted === 1 ? (
              <RightIcon height={25} data-tip data-for={tooltipId} />
            ) : (
              <Pending height={25} data-tip data-for={tooltipId} />
            )}
            <ReactTooltip id={tooltipId} effect="solid">
              <span>{tooltipMessage}</span>
            </ReactTooltip>
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row.adminApproval,
      sortable: true,
      style: columnStyling,
      cell: (row) => {
        const tooltipId = `status-tooltip-${row.approval_status}`;
        const tooltipMessage = getAdminStatusName(row.approval_status);
        return (
          <div>
            {row.approval_status === 1 ? (
              <RightIcon height={25} data-tip data-for={tooltipId} />
            ) : row?.approval_status === 2 ? (
              <Remarks height={25} data-tip data-for={tooltipId} />
            ) : row?.approval_status === 3 ? (
              <PendingPayment height={25} data-tip data-for={tooltipId} />
            ) : (
              <Pending height={25} data-tip data-for={tooltipId} />
            )}
            <ReactTooltip id={tooltipId} effect="solid">
              <span>{tooltipMessage}</span>
            </ReactTooltip>
          </div>
        );
      },
    },
    {
      name: "Actions",
      sortable: true,
      cell: (row) => (
        <Images
          src={Eye}
          onClick={() => {
            onViewSipApplication(row);
          }}
        />
      ),
    },
  ];
  const submittedLoeColumn = [
    {
      selector: "checkbox",
      width: "50px",
      cell: (row) => (
        <>
          {/* <input
          style={{height: "20px", width: "20px"}}
          type="checkbox"
          checked={row.selected} // You need to handle this value in your data
          onChange={() => handleRowSelection(row)}
        /> */}
        </>
      ),
    },
    {
      selector: (row) => row.title + row.intern_type,
      sortable: true,
      style: columnStylingSubmittedApplication,
      center: true,
      width: "250px",
      cell: (row, index) => {
        return (
          <>
            <Row
              style={{
                position: "absolute",
                left: "0",
              }}
              // onClick={() => onRowClicked(row)}
            >
              <Col lg="">
                <span
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "0px",
                    color: "#D04C8D",
                    fontSize: "20px",
                  }}
                >
                  {row.title}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{getInternTypeName(row.intern_type)}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: "Start Date",
      selector: (row) =>
        ["-", "", null].includes(row.exp_start_date)
          ? "-"
          : ConvertDate(row.exp_start_date, "dd/mm/yyyy"),
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "End Date",
      selector: (row) =>
        ["-", "", null].includes(row.exp_end_date)
          ? "-"
          : ConvertDate(row.exp_end_date, "dd/mm/yyyy"),
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "Eligible Interns",
      selector: (row) => {
        return `${row.eligible_interns} / ${row.total_interns}`;
      },
      sortable: true,
      center: true,
      style: columnStyling,
      width: "250px",
    },
    {
      name: "Internship Evaluation",
      selector: (row) => {
        return `${row.completed_evaluations} / ${row.total_interns}`;
      },
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "Completion of Internship",
      selector: (row) => row.completionOfInternship,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {row.completion_form_submitted === 1 ? (
              <RightIcon height={25} />
            ) : (
              <CrossIcon height={25} />
            )}
          </div>
        );
      },
    },
    {
      name: "Admin Action",
      selector: (row) => row.adminApproval,
      sortable: true,
      style: columnStyling,
      cell: (row) => {
        return (
          <div>
            {row.approval_status === 1 ? (
              <RightIcon height={25} />
            ) : (
              <CrossIcon height={25} />
            )}
          </div>
        );
      },
    },
    {
      name: "Actions",
      sortable: true,
      cell: (row) => (
        <Images
          src={Eye}
          onClick={() => {
            onViewSipApplication(row);
          }}
        />
      ),
    },
  ];
  const reviewColumn = [
    {
      name: "Internship Name",
      selector: (row) => row.title + row.intern_type,
      style: columnStylingBigger,
      center: true,
      width: "250px",
      cell: (row, index) => {
        return (
          <>
            <Row
              style={{
                position: "absolute",
                left: "0",
              }}
              // onClick={() => onRowClicked(row)}
            >
              <Col lg="">
                <span
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "0px",
                    color: "#D04C8D",
                    fontSize: "14px",
                  }}
                >
                  {row.title}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{getInternTypeName(row.intern_type)}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: "Start Date",
      selector: (row) =>
        ["-", "", null].includes(row.exp_start_date)
          ? "-"
          : ConvertDate(row.exp_start_date, "dd/mm/yyyy"),
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "End Date",
      selector: (row) =>
        ["-", "", null].includes(row.exp_end_date)
          ? "-"
          : ConvertDate(row.exp_end_date, "dd/mm/yyyy"),
      sortable: true,
      center: true,
      style: columnStyling,
    },
    {
      name: "Eligible Interns",
      selector: (row) => {
        return `${row.eligible_interns} / ${row.total_interns}`;
      },
      style: columnStyling,
    },
    {
      name: "Internship Evaluation",
      selector: (row) => {
        return `${row.completed_evaluations} / ${row.total_interns}`;
      },
      left: true,
      style: columnStyling2,
    },
    {
      name: "Completion of Internship",
      selector: (row) => {
        return (
          <div>
            {row.completion_form_submitted === 1 ? (
              <RightIcon height={25} />
            ) : (
              <CrossIcon height={25} />
            )}
          </div>
        );
      },
    },
    // {
    //   name: "Action",
    //   selector: (row)=> row.action,
    // },
  ];

  const ReviewContent = () => {
    return (
      <div>
        <DataTableWrapper1 className="users-management">
          <DataTable
            columns={reviewColumn}
            data={selectedData}
            customStyles={customStyles}
          />
        </DataTableWrapper1>

        <ReviewButtonWrapper>
          <Button name="Continue" type="submit" onClick={openConfirm} />
          <Button
            secondary
            name="Cancel"
            onClick={() => setReviewModal(false)}
          />
        </ReviewButtonWrapper>
      </div>
    );
  };

  const ConfirmSubmit = () => {
    const advertisedLength = selectedAdvertised.length || 0;
    const generalLength = selectedGeneral.length || 0;
    const directHiringLength = selectedDirectHiring.length || 0;
    return (
      <div>
        <ModalHeaderTextThree>
          {/* {"You are now submitting "+ 
          (advertisedLength === 1 ? `${advertisedLength} MySIP Advertised Internship` : advertisedLength > 0 ? `${advertisedLength} MySIP Advertised Internships` : '') +
          (generalLength === 1? `${generalLength} General Internship`: generalLength > 0 ? ` ${generalLength} General Internships` : '') +
          (directHiringLength === 1 ? `${directHiringLength} MySIP Direct Hiring Internship`: directHiringLength > 0 ? ` ${directHiringLength} MySIP Direct Hiring Internships` : '') +
          " ."+ " Your application will be under review and if approved you will be notified once it is available for download."} */}
          You are now submitting for MySIP LOE Approval. Your application will
          be reviewed short after and a notification will be sent upon approval
          for the availability to download.
        </ModalHeaderTextThree>
        <ModalHeaderError>{LOEApplication.submitWarningLabel}</ModalHeaderError>
        <ReviewButtonWrapper>
          <Button name="Submit" onClick={handleSubmit} />
          <Button
            secondary
            name="Cancel"
            onClick={() => setConfirmModal(false)}
          />
        </ReviewButtonWrapper>
      </div>
    );
  };
  const onInternshipSubmit = () => {
    setIsOpen(false);
  };

  const onViewInternship = () => {
    setIsOpen(true);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    if (isNaN(date)) {
      // Handle invalid date
      return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const onNavigateType = () => {
    if (userRole?.user_details.internship_modify !== "Y") {
      return setUnAuth(true);
    }
    // navigate("/internships/temporary-view")
    const selectedCardIdsString = selectedCard.join(",");

    navigate(
      `/internships/createinternship/selectinternshiptype?selectedIds=${selectedCardIdsString}`
    );
  };

  const ModalContent = () => {
    console.log(selectedCard, "SELECTED");
    return (
      <div>
        {internshipModuleData?.length > 0 ? (
          <FormLabel style={{ textAlign: "center" }}>
            Select at least one internship module to create internship
          </FormLabel>
        ) : null}
        {internshipModuleData.length > 0 ? (
          internshipModuleData.map((list, index) => (
            <>
              <InternCard
                key={list.id}
                data={list}
                selected={selectedCard}
                handleCardClick={handleCardClick}
              />
            </>
          ))
        ) : (
          <p className="noDataFound" style={{ textAlign: "center" }}>
            {
              "Please add an MySIP internship module to create MySIP internships"
            }
          </p>
        )}
        {/* <ButtonWrapper> */}
        {/* <AddButtonWrapper>
          <CusButton
            secondary
            onClick={onNavigateForm}
            disabled={isModuleCreated}
            data-tip={
              isModuleCreated
                ? "Create MySIP internship module only allowed once a year"
                : ""
            }
          >
            <IconSection src={UploadPlusIcon} /> Add
          </CusButton>
          <ReactTooltip effect="solid" className="tooltip-custom-width" />
        </AddButtonWrapper> */}

        {/* </ButtonWrapper> */}
      </div>
    );
  };

  const Content = () => {
    console.log(selectedCard, "SELECTED");
    return (
      <div>
        {internshipModuleData?.length > 0 ? (
          <FormLabel style={{ textAlign: "center" }}>
            Select atleast one internship module to create internship
          </FormLabel>
        ) : null}
        {internshipModuleData.length > 0 ? (
          internshipModuleData.map((list, index) => (
            <>
              <InternCard
                key={list.id}
                data={list}
                selected={selectedCard}
                handleCardClick={handleCardClick}
              />
            </>
          ))
        ) : (
          <p className="noDataFound">
            {"Please add an internship module to create internships"}
          </p>
        )}
        {/* <ButtonWrapper >
                  <CusButton secondary onClick={onNavigateForm}  
                  disabled={isModuleCreated} 
                  data-tip={isModuleCreated? "Create internship module only allowed once a year": ""}
                  >
                  <IconSection src={UploadPlusIcon}/> Add
                  </CusButton>
                  <ReactTooltip effect="solid" className="tooltip-custom-width" />
                </ButtonWrapper> */}

        <ButtonWrapper2>
          <Button
            name={"Create Internship"}
            onClick={() => {
              if (selectedCard.length > 0) {
                // navigate("/loe-application/loe-internship-module")
                navigate("/internship/create-internship/new-internship", {
                  state: {
                    typeOrigin: "on-going",
                    id: selectedCard[0],
                    fromDataMigration: true,
                    fromLandingPage: true,
                  },
                });
              }
            }}
            disabled={selectedCard.length === 0}
          />
        </ButtonWrapper2>
      </div>
    );
  };
  console.log("INTERNSHIP LIST", loeSubmissionInternshipList);
  const [loe_file, setLoeFile] = useState();
  return (
    <>
      {isOpen === true ? (
        <ViewLOE
          title={"VIEW LOE"}
          selectedYear={loeYear}
          setOpen={setIsOpen}
          isOpen={isOpen}
          pdf_file={loe_file}
          onInternshipSubmit={onInternshipSubmit}
        />
      ) : (
        ""
      )}
      <MainLayout>
        <HeaderText>{LOEApplication.header}</HeaderText>
        <HistoryWrapper>
          <HistoryHeaderWrapper>
            <HistoryHeader>{LOEApplication.historyHeader}</HistoryHeader>
            <HistoryHeaderLabel>
              You may download your Letter of Endorsement (LOE) after it has
              been reviewed and approved.
            </HistoryHeaderLabel>
            {/* <hr style={{opacity: 0.5, height: "2px", marginTop: "-5px"}}/> */}
          </HistoryHeaderWrapper>
          {loeHistory && loeHistory.length > 0 ? (
            <DataTableWrapper1 className="users-management">
              <DataTable
                columns={historyColumn}
                data={loeHistory}
                theme="solarized"
                customStyles={customStyles}
                progressComponent={
                  <div
                    style={{
                      minHeight: "586px",
                      backgroundColor: `${backgroundColor} !important`,
                    }}
                  >
                    <DataTableLoaderWrapper>
                      <LoaderWrapper>
                        <Loader />
                      </LoaderWrapper>
                    </DataTableLoaderWrapper>
                  </div>
                }
                noDataComponent={
                  <div
                    style={{
                      minHeight: "586px",
                      backgroundColor: `${backgroundColor} !important`,
                    }}
                  ></div>
                }
              />
            </DataTableWrapper1>
          ) : (
            <TableWrapper1>
              <CustomHeading>No Available Data</CustomHeading>
            </TableWrapper1>
          )}
        </HistoryWrapper>

        {submittedLoeApplicationList.length > 0 ? (
          <LOEAppWrapper>
            <ApplicationHeaderWrapper2 margin={true}>
              <ApplicationHeader>
                {LOEApplication.applicationHeader}
              </ApplicationHeader>

              <ValidityText>
                Valid from:{" "}
                {ConvertDate(loeApplications?.validity_startdate, "dd/mm/yyyy")}{" "}
                -{" "}
                {loeApplications?.validity_enddate
                  ? ConvertDate(loeApplications.validity_enddate, "dd/mm/yyyy")
                  : "N/A"}
              </ValidityText>
            </ApplicationHeaderWrapper2>
            <DataTableWrapper1 className="users-management">
              <DataTable
                columns={submittedLoeColumn}
                data={submittedLoeApplicationList}
                theme="solarized"
                customStyles={customStyles}
                progressComponent={
                  <div
                    style={{
                      minHeight: "586px",
                      backgroundColor: `${backgroundColor} !important`,
                    }}
                  >
                    <DataTableLoaderWrapper>
                      <LoaderWrapper>
                        <Loader />
                      </LoaderWrapper>
                    </DataTableLoaderWrapper>
                  </div>
                }
                noDataComponent={
                  <div
                    style={{
                      minHeight: "586px",
                      backgroundColor: `${backgroundColor} !important`,
                    }}
                  >
                    <DataTableLoaderWrapper>
                      <LoaderWrapper>
                        <CustomHeading>{"No Results Found"}</CustomHeading>
                      </LoaderWrapper>
                    </DataTableLoaderWrapper>
                  </div>
                }
              />
            </DataTableWrapper1>
          </LOEAppWrapper>
        ) : (
          ""
        )}
        {nonMigratedData &&
        nonMigratedData?.total_unassigned_intern_count > 0 ? (
          <LOEAppWrapper>
            <ApplicationHeaderWrapper2 margin={true}>
              <ApplicationHeader>{"Data Migration"}</ApplicationHeader>

              <ValidityText>{`Unassinged ${nonMigratedData?.total_unassigned_intern_count} interns`}</ValidityText>
            </ApplicationHeaderWrapper2>
            <ModalHeaderTextFive style={{ paddingBottom: "10px" }}>
              {LOEApplication.dataMigrationDesp1}
            </ModalHeaderTextFive>
            <ModalHeaderTextFive>
              {LOEApplication.dataMigrationText}
            </ModalHeaderTextFive>
            <ModalHeaderTextFive>
              {LOEApplication.dataMigrationStep1}
            </ModalHeaderTextFive>
            <ModalHeaderTextFive>
              {LOEApplication.dataMigrationStep2}
            </ModalHeaderTextFive>
            <ModalHeaderTextFive>
              {LOEApplication.dataMigrationStep3}
            </ModalHeaderTextFive>
            <ModalHeaderTextFive>
              {LOEApplication.dataMigrationStep4}
            </ModalHeaderTextFive>
            <ButtonWrapper2>
              <Button
                name={"Complete Migration"}
                onClick={() => {
                  if (nonMigratedData) {
                    if (internshipModuleData.length > 0) {
                      onShow();
                    } else {
                      onCompleteMigration();
                    }
                  }
                }}
                // disabled = {selectedCard.length === 0}
              />
            </ButtonWrapper2>
          </LOEAppWrapper>
        ) : (
          <></>
        )}

        <LOEAppWrapper>
          <ApplicationHeaderWrapper
            isRight={submittedLoeApplicationList.length > 0 ? true : ""}
          >
            {submittedLoeApplicationList.length > 0 ? (
              ""
            ) : (
              <ApplicationHeader>
                {LOEApplication.applicationHeader}
              </ApplicationHeader>
            )}
            {console.log(loeApplications, "LOEAPPLICATIONDATA")}
            {loeApplications?.status !== "active" ? (
              ""
            ) : (
              <ButtonWrapper onClick={handleAddSIP}>
                <ButtonContent>
                  <img src={PlusIcon} />
                  <ButtonText>{LOEApplication.buttonText}</ButtonText>
                </ButtonContent>
              </ButtonWrapper>
            )}
          </ApplicationHeaderWrapper>
          <style type="text/css">
            {`
    .form-check-label > label {
      padding-left:10px
    }
    ::-webkit-scrollbar {
      width: 12px; /* Width of the scrollbar */
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #aaa; /* Color of the scrollbar handle */
      border-radius: 10px; /* Roundness of the scrollbar handle */
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the scrollbar track */
    }
    
    /* Add shadows to both ends of the scrollbar */
    ::-webkit-scrollbar-corner {
      background: transparent; /* Background of scrollbar corner */
    }
    `}
          </style>
          <ModalHeaderTextFour>
            {
              "This is a list of your MySIP internships that you can submit as part of your MySIP Completion of Internship. You can start submitting the MySIP Completion of Internship by 31st Dec each year."
            }{" "}
            {
              "Your application will be reviewed, and you will be able to download your Letter of Endorsement (LOE) in the history section above UPON APPROVAL. Please ensure all the necessary details are provided and the internship completion form is completed for each internship. You may click on the    "
            }
            <Images2 src={Eye} style={{ marginTop: "-5px" }} alt="Eye Icon" />
            {"    icon to view your internship details and completion form."}
          </ModalHeaderTextFour>
          <ModalHeaderTextFour></ModalHeaderTextFour>

          <DataTableWrapper1 className="users-management">
            <DataTable
              columns={columnApplication}
              data={loeSubmissionInternshipList}
              theme="solarized"
              customStyles={customStyles}
              progressComponent={
                <div
                  style={{
                    minHeight: "586px",
                    backgroundColor: `${backgroundColor} !important`,
                  }}
                >
                  <DataTableLoaderWrapper>
                    <LoaderWrapper>
                      <Loader />
                    </LoaderWrapper>
                  </DataTableLoaderWrapper>
                </div>
              }
              noDataComponent={
                <div
                  style={{
                    minHeight: "586px",
                    backgroundColor: `${backgroundColor} !important`,
                  }}
                >
                  <DataTableLoaderWrapper>
                    <LoaderWrapper>
                      <CustomHeading>{"No Results Found"}</CustomHeading>
                    </LoaderWrapper>
                  </DataTableLoaderWrapper>
                </div>
              }
            />
          </DataTableWrapper1>

          {loeApplications?.status !== "active" || !showBtn ? (
            ""
          ) : (
            <SubmitButtonWrapper>
              <Button
                name="Submit Letter of Endorsement Application"
                onClick={onOpen}
                disabled={
                  canSubmit === 0 && loeSubmissionInternshipList.length === 0
                }
                data-tip={message}
              />
              <ReactTooltip
                effect="solid"
                className="tooltip-custom-width-message"
              />
            </SubmitButtonWrapper>
          )}
        </LOEAppWrapper>

        <CreateInternshipModuleModal
          title={"CREATE INTERNSHIP"}
          subTitle={"Internship Module"}
          show={showModal}
          close={() => setShowModal(false)}
          content={ModalContent()}
        />

        <CreateInternshipModuleModal
          title={"CREATE INTERNSHIP"}
          subTitle={"Internship Module"}
          show={showSIP}
          close={onClose}
          content={Content()}
        />
        <ReviewModal
          title={LOEApplication.header}
          subTitle={LOEApplication.reviewModalSubtitle + ` ${getYear}`}
          show={reviewModal}
          close={() => setReviewModal(false)}
          content={ReviewContent()}
        />
        <AlertModal
          title={LOEApplication.header}
          subTitle={LOEApplication.submitModalSubtitle + ` ${getYear}`}
          show={confirmModal}
          close={() => setConfirmModal(false)}
          content={ConfirmSubmit()}
        />
      </MainLayout>
    </>
  );
}
