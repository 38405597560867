import * as yup from "yup";

export const initialStates = {
    comp_type: "",
    msc_status: true,
    contact_full_name_1: "",
    organization: "",
    contact_email_1: "",
    contact_phone_1: "",
    contact_mobile_1: "",
    contact_mob_code1: "",
    contact_full_name_2: "",
    contact_email_2: "",
    contact_phone_2: "",
    contact_mobile_2: "",
    contact_mob_code2: "",
    sector: "",
    sub_sector: "",
    student_objectives: [],
    student_objectives_others: "",
    company_objectives: [],
    company_objectives_others: "",
    activities_offered: [],
    activities_offered_others: "",
    learning_outcome: [],
    question_1: "Yes",
    question_2: "Yes",
    monthly_allowance_1: "",
    question_3: "Yes",
    monthly_allowance_2: "",
    learning_outcome_others: "",
    upload: [],
    file_name: "",
    provide_internal_training: true,
    accept_student: true,
    other_allowance: [],
    terms_condition: false,
    min_10_weeks: 0,
    min_500_SKM1_Diploma: 0,
    min_600_ProfCert_Master: 0

} 

export const schema = yup.object().shape({
    monthly_allowance_1: yup
    .string()
    .required("This is a required field")
    .when("question_2", {
        is: "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-500",
            "Monthly allowance should be greater than or equal to 500",
            function (value) {
              return Number(value) >= 500;
            }
          )
      })
    ,
    monthly_allowance_2: yup
    .string()
    .required("This is a required field")
    .when("question_3", {
        is: "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-600",
            "Monthly allowance should be greater than or equal to 600",
            function (value) {
              return Number(value) >= 600;
            }
          )
      }),
    student_objectives: yup
    .array()
      .test({
      message: "Student objectives is a required field",
      test: (arr) => arr.length > 0,
    }),

    company_objectives: yup
    .array()
      .test({
      message: "Company objectives is a required field",
      test: (arr) => arr.length > 0,
    }),

    // activities_offered: yup
    // .array()
    //   .test({
    //   message: "Choose atleast 1 activities offered",
    //   test: (arr) => arr.length > 0,
    // }),

    learning_outcome: yup
    .array()
      .test({
      message: "Learning outcome is a required field",
      test: (arr) => arr.length > 0,
    }),
    
    // other_allowance: yup
    // .array()
    //   .test({
    //   message: "Choose atleast 1 other additional allowance(s)",
    //   test: (arr) => arr.length > 0,
    // }),

    terms_condition: yup.boolean().oneOf([true], 'You must accept the declaration in order to create internship module.'),
    
    
  });


export const iniialEligibleState = {
    question_1: "",
    question_2: "",
    monthly_allowance_1: "",
    question_3: "",
    monthly_allowance_2: "",
}

export const schema2 = yup.object().shape({
    // question_1: yup.string().required("This is a required field"),
    monthly_allowance_1: yup
    .string()
    .required("This is a required field")
    .when("question_2", {
        is: "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-500",
            "Monthly allowance should be greater than or equal to 500",
            function (value) {
              return Number(value) >= 500;
            }
          )
      })
    ,
    monthly_allowance_2: yup
  .string()
  .required("This is a required field")
  .when(["question_2", "question_3"], {
    is: (question2, question3) => question2 === "Yes" && question3 === "Yes",
    then: yup
      .string()
      .test(
        "is-greater-than-600",
        "Monthly allowance should be greater than or equal to 600",
        function (value) {
          return Number(value) >= 600;
        }
      )
  })
  });
export const eligibility = {
    header: "MySIP Eligibility Check",
    subHeading: "Complete your endorsement for National Structured Internship (MySIP), by filling in the internship Module. This eligibility check is to ensure this internship is eligible for Letter Of Endorsement  (LOE) Submission.",
    subHeadAd: "MySIP Advertised - Eligible for LOE Submission",
    subHeadGen: "General Internships - Not Eligible for LOE Submission",
    question1: "Do you provide internship with a minimum duration of 10 weeks?*",
    question2: "Do you pay a minimum RM500.00 per month or more for student undergoing Sijil Kemahiran Malaysia (SKM) Level 1 to Level 3, Certificate, Diploma, (SKM) Level 4 i.e Diploma Kemahiran Malaysia (DKM) or equivalent to do internship with you?",
    question3: "Do you pay a minimum RM600.00 per month or more for student undergoing (SKM) Level 5 i.e. Diploma Lanjutan Kemahiran Malaysia (DLKM), Bachelor’s Degree, Master’s Degree and Professional Certificate or equivalent to do internship with you?",
    subQuestion: "If yes, what is the actual monthly allowance paid?",
    subQuestionDescription: ""
} 

export const internshipModule = {
    header: "Internship Module",
    subHeading: "Complete your endorsement for National Structured Internship Programme (MySIP), by filling in the internship module.",
    quest1: "Company Type",
    comp_type: [
        "Public Listed Company",
        "Malaysian MNC",
        "Foreign MNC",
        "SME",
        "GLC",
        "Start up"
    ],
    quest2: "Are you a MSC Status Company?",
    quest3: "Contact Person Information 1",
    quest4: "Contact Person Information 2",
    quest5: "Please choose your company industry sector",
    sectors: [
        { 
            id: 1, 
            sector_name: "(A) - Agriculture, forestry and fishing" 
        },
        { 
            id: 2, 
            sector_name: "(B) - Mining and quarrying" 
        },
        { 
            id: 3, 
            sector_name: "(C) - Manufacturing" 
        },
        { 
            id: 4, 
            sector_name: "(D) - Electricity, gas, steam and air conditioning supply"
         },
        { 
            id: 5, 
            sector_name: "(E) - Water supply; sewerage, waste management and remediation activities" 
        },
        { 
            id: 6, 
            sector_name: "(F) - Construction" 
        },
        {
             id: 7, 
             sector_name: "(G) - Wholesale and retail trade; repair of motor vehicles and motorcycles" 
            },
        { 
            id: 8, 
            sector_name: "(H) - Transportation and storage" 
        },
        { 
            id: 9, 
            sector_name: "(I) - Accommodation and food service activities" 
        },
        { 
            id: 10, 
            sector_name: "(J) - Information and communication" 
        },
        { 
            id: 11, 
            sector_name: "(K) - Financial and insurance/takaful activities" 
        },
        { 
            id: 12, 
            sector_name: "(L) - Real estate activities" 
        },
        { 
            id: 13, 
            sector_name: "(M) - Professional, scientific and technical activities" 
        },
        { 
            id: 14, 
            sector_name: "(N) - Administrative and support service activities" 
        },
        { 
            id: 15, 
            sector_name: "(O) - Public administration and defence; compulsory social security" 
        },
        { 
            id: 16, 
            sector_name: "(P) - Education" 
        },
        { 
            id: 17, 
            sector_name: "(Q) - Human health and social work activities" 
        },
        { 
            id: 18, 
            sector_name: "(R) - Arts, entertainment and recreation" 
        },
        { 
            id: 19, 
            sector_name: "(S) - Other service activities" 
        },
        { 
            id: 20, 
            sector_name: "(T) - Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use" 
        },
        { 
            id: 21, 
            sector_name: "(U) - Activities of extraterritorial organizations and bodies" 
        }
    ],
    sub_sector:[
        {
            id: 1,
            sector_id: 1,
            sub_sector_name: "A01 (Agriculture, forestry and fishing) - Crop and animal production, hunting and related service activities"
        },
        {
            id: 2,
            sector_id: 1,
            sub_sector_name: "A02 (Agriculture, forestry and fishing) - Forestry and logging"
        },
        {
            id: 3,
            sector_id: 1,
            sub_sector_name: "A03 (Agriculture, forestry and fishing) - Fishing and aquaculture"
        },
        {
            id: 4,
            sector_id: 2,
            sub_sector_name: "B05 (Mining and quarrying) - Mining of coal and lignite"
        },
        {
            id: 5,
            sector_id: 2,
            sub_sector_name: "B06 (Mining and quarrying) - Extraction of crude petroleum and natural gas"
        },
        {
            id: 6,
            sector_id: 2,
            sub_sector_name: "B07 (Mining and quarrying) - Mining of metal ores"
        },
        {
            id: 7,
            sector_id: 3,
            sub_sector_name: "C10 (Manufacturing) - Manufacture of food products"
        },
        {
            id: 8,
            sector_id: 3,
            sub_sector_name: "C11 (Manufacturing) - Manufacture of beverages"
        },
        {
            id: 9,
            sector_id: 3,
            sub_sector_name: "C12 (Manufacturing) - Manufacture of tobacco products"
        },
        {
            id: 10,
            sector_id: 4,
            sub_sector_name: "D35 (Electricity, gas, steam and air conditioning supply) - Electricity, gas, steam and air conditioning supply"
        },
        {
            id: 11,
            sector_id: 5,
            sub_sector_name: "E36 (Water supply; sewerage, waste management and remediation activities) - Water collection, treatment and supply"
        },
        {
            id: 12,
            sector_id: 5,
            sub_sector_name: "E37 (Water supply; sewerage, waste management and remediation activities) - Sewerage"
        },
        {
            id: 13,
            sector_id: 5,
            sub_sector_name: "E38 (Water supply; sewerage, waste management and remediation activities) - Waste collection, treatment and disposal activities; materials recovery"
        },
        {
            id: 14,
            sector_id: 5,
            sub_sector_name: "E39 (Water supply; sewerage, waste management and remediation activities) - Remediation activities and other waste management services"
        },
        {
            id: 15,
            sector_id: 6,
            sub_sector_name: "F41 (Construction) - Construction of buildings"
        },
        {
            id: 16,
            sector_id: 6,
            sub_sector_name: "F42 (Construction) - Civil engineering"
        },
        {
            id: 17,
            sector_id: 6,
            sub_sector_name: "F43 (Construction) - Specialized construction activities"
        },
        {
            id: 18,
            sector_id: 7,
            sub_sector_name: "G45 (Wholesale and retail trade; repair of motor vehicles and motorcycles) - Wholesale and retail trade and repair of motor vehicles and motorcycles"
        },
        {
            id: 19,
            sector_id: 7,
            sub_sector_name: "G46 (Wholesale and retail trade; repair of motor vehicles and motorcycles) - Wholesale trade, except of motor vehicles and motorcycles"
        },
        {
            id: 20,
            sector_id: 7,
            sub_sector_name: "G47 (Wholesale and retail trade; repair of motor vehicles and motorcycles) - Retail trade, except of motor vehicles and motorcycles"
        },
        {
            id: 21,
            sector_id: 8,
            sub_sector_name: "H49 (Transportation and storage) - Land transport and transport via pipelines"
        },
        {
            id: 22,
            sector_id: 8,
            sub_sector_name: "H50 (Transportation and storage) - Water transport"
        },
        {
            id: 23,
            sector_id: 8,
            sub_sector_name: "H51 (Transportation and storage) - Air transport"
        },
        {
            id: 24,
            sector_id: 8,
            sub_sector_name: "H52 (Transportation and storage) - Warehousing and support activities for transportation"
        },
        {
            id: 25,
            sector_id: 8,
            sub_sector_name: "H53 (Transportation and storage) - Postal and courier activities"
        },
        {
            id: 26,
            sector_id: 9,
            sub_sector_name: "I55 (Accommodation and food service activities) - Accommodation"
        },
        {
            id: 27,
            sector_id: 9,
            sub_sector_name: "I56 (Accommodation and food service activities) - Food and beverage service activities"
        },
        {
            id: 28,
            sector_id: 10,
            sub_sector_name: "J58 (Information and communication) - Publishing activities"
        },
        {
            id: 29,
            sector_id: 10,
            sub_sector_name: "J59 (Information and communication) - Motion picture, video and television programme production, sound recording and music publishing activities"
        },
        {
            id: 30,
            sector_id: 10,
            sub_sector_name: "J60 (Information and communication) - Programming and broadcasting activities"
        },
        {
            id: 31,
            sector_id: 10,
            sub_sector_name: "J61 (Information and communication) - Telecommunications"
        },
        {
            id: 32,
            sector_id: 10,
            sub_sector_name: "J62 (Information and communication) - Computer programming, consultancy and related activities"
        },
        {
            id: 33,
            sector_id: 10,
            sub_sector_name: "J63 (Information and communication) - Information service activities"
        },
        {
            id: 34,
            sector_id: 11,
            sub_sector_name: "K64 (Financial and insurance/takaful activities) - Financial service activities, except insurance/takaful and pension funding"
        },
        {
            id: 35,
            sector_id: 11,
            sub_sector_name: "K65 (Financial and insurance/takaful activities) - Insurance/takaful, reinsurance/takaful and pension funding, except compulsory social activities auxiliary to financial service and insurance/takaful activities"
        },
        {
            id: 36,
            sector_id: 12,
            sub_sector_name: "L68 (Real estate activities) - Real estate activities"
        },
        {
            id: 37,
            sector_id: 13,
            sub_sector_name: "M69 (Professional, scientific and technical activities) - Legal and accounting activities"
        },
        {
            id: 38,
            sector_id: 13,
            sub_sector_name: "M70 (Professional, scientific and technical activities) - Activities of head offices; management consultancy activities"
        },
        {
            id: 39,
            sector_id: 13,
            sub_sector_name: "M71 (Professional, scientific and technical activities) - Architectural and engineering activities; technical testing and analysis"
        },
        {
            id: 40,
            sector_id: 13,
            sub_sector_name: "M72 (Professional, scientific and technical activities) - Scientific research and development"
        },
        {
            id: 41,
            sector_id: 13,
            sub_sector_name: "M73 (Professional, scientific and technical activities) - Advertising and market research"
        },
        {
            id: 42,
            sector_id: 13,
            sub_sector_name: "M74 (Professional, scientific and technical activities) - Other professional, scientific and technical activities"
        },
        {
            id: 43,
            sector_id: 13,
            sub_sector_name: "M75 (Professional, scientific and technical activities) - Veterinary activities"
        },
        {
            id: 44,
            sector_id: 14,
            sub_sector_name: "N77 (Administrative and support service activities) - Rental and leasing activities"
        },
        {
            id: 45,
            sector_id: 14,
            sub_sector_name: "N78 (Administrative and support service activities) - Employment activities"
        },
        {
            id: 46,
            sector_id: 14,
            sub_sector_name: "N79 (Administrative and support service activities) - Travel agency, tour operator, reservation service and related activities"
        },
        {
            id: 47,
            sector_id: 14,
            sub_sector_name: "N80 (Administrative and support service activities) - Security and investigation activities"
        },
        {
            id: 48,
            sector_id: 14,
            sub_sector_name: "N81 (Administrative and support service activities) - Services to buildings and landscape activities"
        },
        {
            id: 49,
            sector_id: 14,
            sub_sector_name: "N82 (Administrative and support service activities) - Office administrative, office support and other business support activities"
        },
        {
            id: 50,
            sector_id: 15,
            sub_sector_name: "O84 (Public administration and defence; compulsory social security) - Public administration and defence; compulsory social security"
        },
        {
            id: 51,
            sector_id: 16,
            sub_sector_name: "P85 (Education) - Education"
        },
        {
            id: 52,
            sector_id: 17,
            sub_sector_name: "Q86 (Human health and social work activities) - Human health activities"
        },
        {
            id: 53,
            sector_id: 17,
            sub_sector_name: "Q87 (Human health and social work activities) - Residential care activities"
        },
        {
            id: 54,
            sector_id: 17,
            sub_sector_name: "Q88 (Human health and social work activities) - Social work activities without accommodation"
        },
        {
            id: 55,
            sector_id: 18,
            sub_sector_name: "R90 (Arts, entertainment and recreation) - Creative, arts and entertainment activities"
        },
        {
            id: 56,
            sector_id: 18,
            sub_sector_name: "R91 (Arts, entertainment and recreation) - Libraries, archives, museums and other cultural activities"
        },
        {
            id: 57,
            sector_id: 18,
            sub_sector_name: "R92 (Arts, entertainment and recreation) - Gambling and betting activities"
        },
        {
            id: 58,
            sector_id: 18,
            sub_sector_name: "R93 (Arts, entertainment and recreation) - Sports activities and amusement and recreation activities"
        },
        {
            id: 59,
            sector_id: 19,
            sub_sector_name: "S94 (Other service activities) - Activities of membership organizations"
        },
        {
            id: 60,
            sector_id: 19,
            sub_sector_name: "S95 (Other service activities) - Repair of computers and personal and household goods"
        },
        {
            id: 61,
            sector_id: 19,
            sub_sector_name: "S96 (Other service activities) - Other personal service activities"
        },
        {
            id: 62,
            sector_id: 20,
            sub_sector_name: "T97 (Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use) - Activities of households as employers of domestic personnel"
        },
        {
            id: 63,
            sector_id: 20,
            sub_sector_name: "T98 (Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use) - Undifferentiated goods- and services-producing activities of private households for own use"
        },
        {
            id: 64,
            sector_id: 21,
            sub_sector_name: "U99 (Activities of extraterritorial organizations and bodies) - Activities of extraterritorial organizations and bodies"
        }
    ],
    quest6: "Please choose your subsector",
    question7: "Objectives for student (Please choose only the best three (3) )*",
    question7List: [
        "To provide students the oppurtunity to test their interest in a particular career before permanent commitments are made.",
        "To develop skills in the application of thoery to practical work of situations.",
        "To develop skills and techniques directly applicable to their careers.",
        "To expose students to real work environment experiences and provide knowledge in report writting for technical work/project.",
        "To build the strength, team spirit, and self-confidence of students.",
        "To enhance student's creativity and interpersonal skills",
        "To build good communication skills with colleagues and learn proper behaviour in a corporate setting.",
        "The student will be instilled with good moral values such as responsibility, commitment, and trustworthiness during their training.",
        "Others (please specify)",
    ],
    question8: "Objectives for company (Please choose only the best three (3) )*",
    question8List: [
        "To gain perspective on organizational issues. Interns challenge the way we've always done it mentality and bring fresh, new ideas to the company",
        "Technology adaption - Social media, computer programs, iPads - these are a piece of cake for young professionals. And, although you're a young entrepreneur, you can always use a hadn from a fellow Gen Y tech-savvy professional.",
        "Its a trial period that could lead to something more. An internship is agreat way to see how much potentials a student or recent graduates has in the field.",
        "Extra resources for project completion. An interest candidate takes on an internship in hopes of accomplishing something to use on their resume or in future interviews.",
        "Gain brand advocates. Hiring an intern helps spread the word about the company-whether you mean to or not.",
        "To create a pool of talent funnel for your company. Using the internship period as training ground/probation for direct recruitment.",
        "Others (please specify)"
    ],
    question9: "Activities Offered During Internship (You may choose more than one )*",
    question9List: [
        "Introduction",
        "Job Rotation",
        "Specific project",
        "Classroom Trainings - Technical skills",
        "Classroom Trainings - Soft skills",
        "Performance  review - Attendance",
        "Performance review - Project/Job assingments",
        "Performance review - Skills",
        "Performance review - Behaviour",
        "Performance review - Exit Behaviour",
        "Performance review - Continuous guidance and engagement from supervisor",
        "Others (please specify)"
    ],
    question10: "Expected learning outcomes for intern (Please choose the best three (3) )*",
    question10List: [
        "Ability to demonstarte skills in problem solving and decision making through hands-on practice.",
        "Ability to develop skills and techniques directly applicable to their careers.",
        "Exposed to real work environment and gain knowledge in writing report in technical works/projects",
        "Interns able to utilise their strength, teamwork spirit, and self-confidence.",
        "Interns able to enhance creativity and idea sharing skills.",
        "Build good communicatio skills with colleagues and learn proper behaviour in a corporate setting.",
        "Others (please specify)"
    ],
    uploadFile: "Please provide any other additional information on the internship programme that was not listed above (if any). You may upload a PDF file if you have different module or approach for your internship porgramme.",
    question11: "Did you provide any internal training for your intern? (eg.: Short course by your internal personal)",
    question12: "Would you accept students whose area of study differs from the scope of work required as interns?",
    subHeading2: "Benefit Offered",
    question13: "Do you offer any other allowance (s) besides the basic monthly allowance? (You may select more than one)*",
    question13List: [
        "Meals",
        "Travelling",
        "Accommodation",
        "Medical",
        "Overtime",
        "Internet Cash Allowance",
        "Annual leave",
        "Persoanl Accident Insurance",
        "Others (please specify)",
        "None of the above"
    ],
    declaration: "I hereby certify that the information provided herein is true and accurate to the best of my knowledge. I hereby consent for Talent Corporation Malaysia Berhad (TalentCorp) to collect, store, process and use my personal data given, herein accordance with the Personal Data Protection Act 2010 for the purpose it was collected, which includes but not limited for administrative purposes in conjunction with TalentCorp’s National Structured Internship Program (MySIP)."
}
