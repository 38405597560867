import { ReactComponent as Home } from "./assets/icons/svg/home.svg";
import { ReactComponent as Settings } from "./assets/icons/svg/settings.svg";
import { ReactComponent as Internships } from "./assets/icons/svg/internships.svg";
import { ReactComponent as Companies } from "./assets/icons/svg/companies.svg";
import { ReactComponent as UserManagement } from "./assets/icons/svg/icon_grey.svg";
import { ReactComponent as Internship } from "./assets/icons/svg/Internship.svg";
import { ReactComponent as TA } from "./assets/icons/svg/TA.svg";
import { ReactComponent as EM } from "./assets/icons/svg/EM.svg";
import { ReactComponent as MyCompany } from "./assets/icons/svg/Company.svg";
import { ReactComponent as AD } from "./assets/icons/svg/AD.svg";
import { ReactComponent as CD } from "./assets/icons/svg/CD.svg";
import { ReactComponent as Calendar } from "./assets/icons/svg/calendar.svg";
import { ReactComponent as CompanyBranding } from "./assets/icons/svg/CompBrand.svg";
import { ReactComponent as OpenSubMenu } from "./assets/icons/svg/down.svg";
import { ReactComponent as CloseSubMenu } from "./assets/icons/svg/downPink.svg";
import { ReactComponent as LOE } from "./assets/svg/loe-application.svg";

import AuthRoute from "./utilities/authRoute";
export const menus = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <Home />,
    // activeIcon: <img src={ActiveHome} />,
    // icon: Home,
    // isOpen: <OpenSubMenu  style={{transform: "rotate(180deg)" }}/>,
    // isClosed:<CloseSubMenu />,
    // children:[


    // ]
  },
  {
    name: "Internships",
    path: "/internships",
    icon: <Internship />,

    // activeIcon: <img src={ActiveIntern} />,
    // icon: Jobs,
  },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    icon: <Companies width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
  {
    name: "My Company",
    path: "/my-company",
    icon: <MyCompany width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
  {
    name: "My Profile",
    path: "/my-profile",
    icon: <Settings width={"19px"} />,

  },
  {
    name: "Talent Acquision",
    // path: "/talent-acquistion",
    isOpen: <OpenSubMenu style={{ transform: "rotate(180deg)" }} />,
    isClosed: <CloseSubMenu />,
    icon: <TA />,
    children: [
      {
        name: "Talent Search",
        path: "/talentsearch",
        icon: <EM />,

      },
      {
        name: "Internships",
        path: "/internships",
        icon: <Internship />,
      },

      {
        name: "Jobs",
        path: "/jobs",
        icon: <Internships />,
      },
      {
        name: "Track Applications",
        path: "/track-applications",
        icon: <TA />,
      },
      // {
      //   name: "Track Applications",
      //   path: "/track-applications",
      //   icon: <TA />,
      //   // activeIcon: <img src={ActiveIntern} />,
      //   // icon: Jobs,
      // },

      {
        name: "Scheduling",
        path: "/my-calendar",
        icon: <Calendar />,
        // activeIcon: <img src={ActiveIntern} />,
        // icon: Jobs,
      },
    ]
  }, 
  {
    name: "Talent Management",
    // path: "/talent-acquistion",
    isOpen: <OpenSubMenu style={{ transform: "rotate(180deg)" }} />,
    isClosed: <CloseSubMenu />,
    icon: <TA />,
    children: [
      {
        name: "Employee Management",
        path: "/employeeManagement",
        icon: <EM />,
        // activeIcon: <img src={ActiveCompany} />,
        // icon: Company,
      },
      {
        name: "Psychometric Analytics",
        path: "/psychometric-analytics",
        icon: (
          <AuthRoute>
            <AD />
          </AuthRoute>
        ),
        // activeIcon: <img src={ActiveCompany} />,
        // icon: Company,
      },
      // {
      //   name: "Coaching Dashboard",
      //   path: "/coaching-dashboard",
      //   icon: <CD />,
      //   // activeIcon: <img src={ActiveCompany} />,
      //   // icon: Company,
      // },
    ]
  },
  {
    name: "National Structured Internship Programme (MySIP) Application",
    path: "/loe-application",
    icon: <LOE/>
   },
 
  // {
  //   name: "Coaching Dashboard",
  //   path: "/coaching-dashboard",
  //   icon: <CD />,
  //   // activeIcon: <img src={ActiveCompany} />,
  //   // icon: Company,
  // },
 
]

//   {
//     name: "Users",
//     // path: "/talent-acquistion",
//         isOpen: <OpenSubMenu  style={{transform: "rotate(180deg)" }}/>,
//     isClosed:<CloseSubMenu />,
// icon:<UserManagement/>,
// children: [
//   {
//     name: "User Management",
//     path: "/user-management",
//     icon: <UserManagement />,
//     // activeIcon: <img src={ActiveCompany} />,
//     // icon: Company,
//   },

//   {
//     name: "Users",
//     // path: "/talent-acquistion",
//         isOpen: <OpenSubMenu  style={{transform: "rotate(180deg)" }}/>,
//     isClosed:<CloseSubMenu />,
// icon:<UserManagement/>,
// children: [
//   {
//     name: "User Management",
//     path: "/user-management",
//     icon: <UserManagement />,
//     // activeIcon: <img src={ActiveCompany} />,
//     // icon: Company,
//   },

// ]
//   },



export const menuDropDown = [
  {
    name: "My Profile",
    path: "/my-profile",
    icon: <Settings width={"19px"} />,

  },
  {
    name: "My Company",
    path: "/my-company",
    icon: <MyCompany width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
  {
    name: "Company Branding",
    path: "/companybranding",
    icon: <CompanyBranding width={"19px"} />,
    // activeIcon: <img src={ActiveIntern} />,
    // icon: Jobs,
  },

  {
    name: "Subscriptions",
    path: "/subscriptions",
    icon: <Companies width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
  {
    name: "Annual Survey",
    path: "/annual-survey",
    icon: <Companies width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
]

export const menusNewList = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <Home />,

  },
  {
    name: "Internships",
    path: "/internships",
    icon: <Internship />,
    // activeIcon: <img src={ActiveIntern} />,
    // icon: Jobs,
  },
  {
    name: "National Structured Internship Programme (MySIP) Application",
    path: "/loe-application",
    icon: <LOE/>
   },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    icon: <Companies width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
  {
    name: "My Company",
    path: "/my-company",
    icon: <MyCompany width={"19px"} />,
    // activeIcon: <img src={ActiveCompany} />,
    // icon: Company,
  },
  {
    name: "My Profile",
    path: "/settings",
    icon: <Settings width={"19px"} />,

  },
]