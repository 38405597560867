export const LOEApplication={
    header: "Manage MySIP (National Structured Internship Program) ",
    historyHeader: "MySIP History ",
    applicationHeader: "MySIP Application",
    submittedHeader: "Submitted Application",
    buttonText: "Add MySIP Direct Hiring Internship",
    reviewModalSubtitle: "Review Application",
    submitModalSubtitle: "Submit Letter of Endorsement Application",
    submitWarningLabel: "*Note: Once submitted, you will not be able to add any other internships into this application.",
    dataMigrationDesp1: "You have submitted the details of a total of 40 interns manually. In order for you to submit these interns as part of your National Structured Internship Programme (MySIP) Application, you are required to have an Internship Module and an Internship Type attached to each intern.",
    dataMigrationText: "Please click on Complete Migration and follow the process. You will be asked to :",
    dataMigrationStep1: "1. Create an Internship Module (only if you don’t already have one)",
    dataMigrationStep2: "2. Create an Internship Type",
    dataMigrationStep3: "3. Assign interns from your migrated interns details to Internship Type",
    dataMigrationStep4: "4. If there are any interns remaining, You will be asked to create another Internship Type and to assign interns to it until you have no remaining unassigned interns ",
}