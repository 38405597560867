import MainLayout from "../../layouts/mainLayout/mainLayout";
import rightarrow from "../../assets/icons/arrow_right.png";
import { useNavigate, useLocation  } from "react-router-dom";
import { useSelector } from "react-redux";
import { eligibility } from "./staticContentInternship";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import React, { useState, useEffect } from "react";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import CreateInternshipModuleModal from "../../components/createInternshipModule/createInternshipModal";
import UploadPlusIcon from "../../assets/uploader.png";
import InternCard from "../../components/InternshipCard/internCard";
import { iniialEligibleState } from "./staticContentInternship";
import { Controller } from "react-hook-form";
import {  useForm  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema2 } from "./staticContentInternship";
import { getInternshipModuleList } from "../../services/apiCalls";
import ErrorField from "../../components/errorField/errorField";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
const NewInternPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 50px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const SectionWrapper = styled.section``;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  max-width: 900px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;
const WarningLable = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
`;
const WarningDescription = styled.li`
  font-weight: 500;
  font-size: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  color: ${props => (props.overallEligibility ? "black" : "red")};`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 40px
}
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 50px;
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const CusButton = styled.button`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 10px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
border-style: ${(props) => (props.secondary ? "dotted" : "none")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;
const IconSection = styled.img`
  margin-right: 5px;
  width: 30px;
  height: 30px;
  filter: ${(props) => (props.hovered ? 'invert(1)' : 'none')};
  transition: filter 0.2s ease-in-out;
`;
const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: center;
`;
export default function EligibilityCheck({setUnAuth}){
    const navigate = useNavigate();
    const userRole = useSelector((state) => state.company.currentOrgData);
    const [showSIP, setShowSIP] = useState(false);
    const [isEligible, setIsEligible] = useState("Yes");
    const [question1Eligible, setQuestion1Eligible] = useState(true);
    const [question2Eligible, setQuestion2Eligible] = useState(true);
    const [question3Eligible, setQuestion3Eligible] = useState(true);
    const [question4Eligible, setQuestion4Eligible] = useState(true);
    const [monthlyAllowanceBelow500, setMonthlyAllowanceBelow500] = useState(false);
    const [monthlyAllowanceBelow600, setMonthlyAllowanceBelow600] = useState(false);
    const location = useLocation();
    const origin = location.state?.origin;
    const successInternshipModule = location.state?.createdInternshipModule;
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
    const [loading, setLoading] = useState(true);
    // const [isGeneral, setIsGeneral] = useState(localStorage.getItem("isGeneral"));
    // const [isSIP, setIsSIP] = useState(localStorage.getItem("isSIP"));
    //testing state
    // const isGeneral = localStorage.getItem("isGeneral");
    // const isSIP = localStorage.getItem("isSIP");
    console.log("CODEEEEE>>>>"+origin)
    const {
      control,
      watch,
      reset: resetForm,
      setError: setErrorCustom,
      setValue: setEligibleValue,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: { ...iniialEligibleState },
      resolver: yupResolver(schema2),
      mode: "onSubmit",
    });

    const formval = watch();
    // const overallEligibility = question1Eligible && question2Eligible && question3Eligible && question4Eligible;
    const overallEligibility = (
      (question1Eligible && question2Eligible) &&  // Both first two questions must be "Yes"
      (!question3Eligible || question4Eligible) && 
      (!monthlyAllowanceBelow500 && !monthlyAllowanceBelow600)
    )
    console.log("DATATA>>>"+JSON.stringify(formval.question_2));
    const [internshipModuleData, setInternshipModuleData] = useState([]);
    const [createdYear, setCreatedYear] = useState([]);
    const currentYear = new Date().getFullYear();
    const [isModuleCreated, setIsModuleCreated] = useState(false);
    console.log(currentYear, "CURRENt")
    useEffect( async ()=>{
     
        const {data} = await getInternshipModuleList(org_code);
      if(data){
        const createdYears = data.map(item => item.created_at);
        const years = createdYears.map(created_at => new Date(created_at).getFullYear());
        setCreatedYear(years);
        if (years.includes(currentYear)) {
          setIsModuleCreated(true);
        }
        setInternshipModuleData(data);
        setLoading(false);
      }else{
        setLoading(false);
      }
   
    },[org_code])
    console.log("Internship Module Retrieved>>>",internshipModuleData);
    console.log("Internship created year>>>",createdYear);
    const data = [
      {
          id: 1,
          sampleName: "Internship Module 1",
          expiredDate: "02/02/2023"
      },
      {
          id: 2,
          sampleName: "Internship Guardian",
          expiredDate: "05/07/2023"
      }
  ]

    const [eligibleData, setEligibleData] = useState(formval);

    
   
    console.log(eligibleData);

      useEffect(()=>{
        if(successInternshipModule === "success"){
          onShow();
        }
      },[])

const onNavigateForm = () =>{
  if (userRole?.user_details.internship_modify !== "Y") {
    return setUnAuth(true);
  }
  navigate("/internships/internship-module",  { state: { data: eligibleData , from: "loe"} } );
}
const [selectedCard, setSelectedCard] = useState([]);

const handleCardClick = (cardId) => {
  if (selectedCard.includes(cardId)) {
    setSelectedCard(selectedCard.filter((id) => id !== cardId));
  } else {
    setSelectedCard([...selectedCard, cardId]);
  }
};
const onNavigateType = () =>{
  if (userRole?.user_details.internship_modify !== "Y") {
    return setUnAuth(true);
  }
  // navigate("/internships/temporary-view")
  const selectedCardIdsString = selectedCard.join(",");

  navigate(`/internships/createinternship/selectinternshiptype?selectedIds=${selectedCardIdsString}`)
}
const onShow = () =>{
  setShowSIP(true);
}

const handleContinue = (data)=>{
  if(formval.monthly_allowance_1 === "" || formval.monthly_allowance_2 === ''){
    toast.error("Check you data", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  if(Object.keys(errors).length > 0){
    toast.error("Please check you data and enter correct values", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  else {
    setEligibleData(formval);
    localStorage.setItem("eligibilityData", JSON.stringify(formval));
      if(overallEligibility){
        onShow();
  }
}
}

const onClose =()=>{
setShowSIP(false);
}

const Content =()=>{
 
  console.log(selectedCard, "SELECTED")
    return(
      <div>
       {internshipModuleData?.length > 0?(
        <FormLabel style={{textAlign: "center"}}>Select at least one MySIP internship module to create MySIP internship</FormLabel>
       ): null}
         {internshipModuleData.length > 0 ? (
                internshipModuleData.map((list, index) => (
                  <>
                  <InternCard 
                    key={list.id}
                    data={list}
                    selected={selectedCard}
                    handleCardClick={handleCardClick}/> 
                    </>
                  
                ))
              ) : (
                <p className="noDataFound">{"Please add an internship module to create internships"}</p>
              )}
              <ButtonWrapper >
                <CusButton secondary onClick={onNavigateForm}  
                disabled={isModuleCreated} 
                data-tip={isModuleCreated? "Create MySIP internship module only allowed once a year": ""}
                >
                <IconSection src={UploadPlusIcon}/> Add
                </CusButton>
                <ReactTooltip effect="solid" className="tooltip-custom-width" />
              </ButtonWrapper>
          
         <ButtonWrapper2>
         <Button
          name={"Create MySIP Internship"}
          onClick={onNavigateType}
          disabled = {selectedCard.length === 0}
          width={"300px"}
              />
         </ButtonWrapper2>
      </div>
     
      
    )
  }
    return(
        <MainLayout>
            <div className="breadcrumb">
            <p onClick={() => navigate("/loe-application")} style={{ cursor: "pointer" }}>
              {"National Structured Internship Programme (MySIP) Application"}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>{"Eligibility Check"}</p>
          </div>
          <NewInternPageWrapper>
            <SectionWrapper>
                <HeaderText>{eligibility.header}</HeaderText>
                <Description>{eligibility.subHeading}</Description>
            </SectionWrapper>
            <BreakSection>
                <hr/>
            </BreakSection>
            <form onSubmit={handleSubmit(handleContinue)}>
            <GroupWrapper>
                <FormLabel>{eligibility.question1}</FormLabel>
                <Controller
                name="question_1"
                control={control}
                defaultValue={"Yes"}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes")
                          setQuestion1Eligible(true)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=>{ 
                            field.onChange("No")
                            setQuestion1Eligible(false)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.question2}</FormLabel>
                <Controller
                name="question_2"
                control={control}
                defaultValue={"Yes"}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes")
                            setQuestion2Eligible(true)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=> {
                            field.onChange("No")
                            setQuestion2Eligible(false)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.subQuestion}</FormLabel>
                <span className="jonNation">{eligibility.subQuestionDescription}</span>
                <div style={{ marginTop: "10px" }}>
                  <Controller
                    name="monthly_allowance_1"
                    control={control}
                    defaultValue="" 
                    render={({ field }) => (
                      <Input
                        placeholder="5000"
                        {...field} 
                        onChange={(e) => {
                          field.onChange(e);
                          const value = e.target.value;
                          const numericValue = parseFloat(value.replace(/[^\d.]/g, '')); // Extract numeric value
                          setMonthlyAllowanceBelow500(numericValue < 500);
                        }}
                      />
                    )}
                  />
                  {errors.monthly_allowance_1 && (
                    <ErrorField err={errors.monthly_allowance_1.message}/>
                  )}
        </div>
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.question3}</FormLabel>
                <Controller
                name="question_3"
                control={control}
                defaultValue={"Yes"}
                render={({field}) => (
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes");
                            setQuestion3Eligible(true);
                          }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=> {
                            field.onChange("No")
                            setQuestion3Eligible(false)
                          }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.subQuestion}</FormLabel>
                <span className="jonNation">{eligibility.subQuestionDescription}</span>
                <div style={{ marginTop: "10px" }}>
                    <Controller
                      name="monthly_allowance_2"
                      control={control}
                      defaultValue="" 
                      render={({ field }) => (
                        <Input
                          placeholder="5000"
                          {...field} 
                          onChange={(e) => {
                            field.onChange(e);
                            const value = e.target.value;
                            const numericValue = parseFloat(value.replace(/[^\d.]/g, '')); // Extract numeric value
                            setMonthlyAllowanceBelow600(numericValue < 600);
                          }}
                        />
                      )}
                    />
                    {errors.monthly_allowance_2 && (
                    <ErrorField err={errors.monthly_allowance_2.message}/>
                  )}
              </div>
            </GroupWrapper>
            
            {/* {overallEligibility ?  (
                <div>
                    <WarningLable>Remarks</WarningLable>
            
            <WarningDescription overallEligibility={overallEligibility}>
            You are eligible to participate in National Structured Internship (MySIP) 2023.
            </WarningDescription>
                </div>
            ): (
                <div>
                    <WarningLable>Remarks</WarningLable>
            
            <WarningDescription overallEligibility={overallEligibility}>
            You are not eligible to participate in National Structured Internship Programme (MySIP) 2023. Please pay aminimun of RM 500.00 per month
            or more for SKM1-SKM5 or Diploma  or a minimum of RM 600.00 per month for Bachelor degree, Master degree or Professional Certificate.
            </WarningDescription>
                </div>
            )} */}

<WarningLable>Notes</WarningLable>
       {question1Eligible && question2Eligible && !monthlyAllowanceBelow500? (
         <WarningDescription overallEligibility={question1Eligible}>You are eligible to participate in National Structured Internship (MySIP). You are now able to create a Direct Hiring Internship to be submitted in your Letter of Endorsement(LOE) Application</WarningDescription>
       ): ""}
        {!question1Eligible ? (
              <WarningDescription overallEligibility={question1Eligible}> You are required to provide minimum 10 weeks of internship duration</WarningDescription>
            ): ( "" )}

        {!question2Eligible ? (
                      
                      <WarningDescription overallEligibility={question2Eligible}>Minimum RM500.00 for Diploma, Sijil Kemahiran Malaysia (SKM) Level 1 to Level 3, Diploma Kemahiran Malaysia (DKM).</WarningDescription>
                  
                  ): (
                     "" 
                  )}
                   {!question1Eligible || !question2Eligible ? (
                    <WarningDescription overallEligibility={question1Eligible && question2Eligible}>You are not eligible to participate in National Structured Internship (MySIP). You will have to be elgible to participate in National Structured Internship (MySIP) in order to create a MySIP Direct Hiring Internship</WarningDescription>       
                     ): ""}

                  {monthlyAllowanceBelow500 && (
                          <WarningDescription>Please pay a monthly allowance of 500 or more</WarningDescription>
                        )}
                                      {question3Eligible && monthlyAllowanceBelow600 ? (
                    <WarningDescription>
                      Please pay a monthly allowance of 600 or more
                    </WarningDescription>
                  ) : null}
            <ButtonWrapper>
                <Button name="Continue" type={"submit"} onClick={handleSubmit(handleContinue)} disabled= {origin === "sip" && !overallEligibility}/>
            </ButtonWrapper>
            </form>
          </NewInternPageWrapper>
          <CreateInternshipModuleModal
        title={"CREATE MYSIP INTERNSHIP"}
        subTitle={"MySIP Internship Module"}
        show={showSIP}
        close={onClose}
        content={Content()}
      />
        </MainLayout>
    )
}