import { onApiCall } from "./CommonApi";
import axios from "axios";

export const authSignin = (formdata) => {
  return onApiCall({
    url: `auth/api/company/login/`,
    method: "POST",
    data: formdata,
  });
};

export const forceLogout = (request) => {
  return onApiCall({
    url: `/auth/api/force-logout/`,
    method: "POST",
    data: { email: request },
  });
};

export const jobAddNotes = (request) => {
  return onApiCall({
    url: `internship/intern-notes/`,
    method: "POST",
    data: request,
  });
};

export const authSignUp = (formdata) => {
  return onApiCall({
    url: `auth/api/company/register-company/`,
    method: "POST",
    data: formdata,
  });
};
export const authLogout = (formdata) => {
  return onApiCall({
    url: `auth/api/company/logout/`,
    method: "GET",
    data: formdata,
  });
};
export const registerOrg_S1 = (formdata) => {
  return onApiCall({
    url: `auth/api/company/register/`,
    method: "POST",
    data: formdata,
  });
};

export const registerOrg_S3 = (formdata) => {
  return onApiCall({
    url: `auth/api/company/user-register/`,
    method: "POST",
    data: formdata,
  });
};

export const uploadObject = (formdata) => {
  return onApiCall({
    url: `base/api/file-obj-upload/`,
    method: "POST",
    data: formdata,
    isFileUpload: true,
  });
};

export const getExistingOrganistion = (formdata) => {
  return onApiCall({
    url: `auth/api/company/register-view/`,
    method: "POST",
    data: formdata,
  });
};

export const updateExistingOrganisation = (formdata) => {
  return onApiCall({
    url: `auth/api/company/register-update/`,
    method: "PUT",
    data: formdata,
  });
};

export const getEmailVerification = (formdata) => {
  return onApiCall({
    url: `auth/api/company/user-validation-register/`,
    method: "POST",
    data: formdata,
  });
};

export const getInternScrollList = (formdata) => {
  return onApiCall({
    url: `company/api/internship-scroll-list/`,
    method: "GET",
    data: formdata,
  });
};

export const getOrgData = (formdata) => {
  return onApiCall({
    url: `auth/api/company/token-object/`,
    method: "GET",
    data: formdata,
  });
};

export const getAllUsers = (org_code) => {
  return onApiCall({
    url: `company/api/company-users/${org_code}`,
    method: "GET",
  });
};

export const deleteCompanyUser = (org_code, formdata) => {
  return onApiCall({
    url: `company/api/company-users/${org_code}`,
    method: "DELETE",
    data: formdata,
  });
};

export const onAddNewUser = (org_code, formdata) => {
  return onApiCall({
    url: `company/api/company-users/${org_code}`,
    method: "POST",
    data: formdata,
  });
};

export const onEditUser = (org_code, formdata) => {
  return onApiCall({
    url: `company/api/company-users/${org_code}`,
    method: "put",
    data: formdata,
  });
};

export const getUserData = (formdata) => {
  return onApiCall({
    url: `auth/api/company/token-object/`,
    method: "GET",
    data: formdata,
  });
};

export const createNewIntern = (formdata) => {
  return onApiCall({
    url: `company/api/internship-create/`,
    method: "POST",
    data: formdata,
  });
};

export const getCompanyData = (formdata) => {
  return onApiCall({
    url: `company/api/company-view/${formdata.code}/`,
    method: "GET",
    data: formdata,
  });
};

export const getCompanies = () => {
  return onApiCall({
    url: `company/api/list/`,
    method: "GET",
  });
};
export const getSkillGap = (internship_id) => {
  return onApiCall({
    url: `company/api/interns-details-for-skills-gap-evaluation/${internship_id}/`,
    method: "GET",
  });
};
export const onAddNewLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-add/${formdata.code}/`,
    method: "POST",
    data: formdata.data,
  });
};

export const submitSingleInternUpload = ({formdata,internship_id}) => {
  console.log("internshipId",internship_id);
  return onApiCall({
    url: `company/api/skills-gap-evaluation-form/${internship_id}/`,
    method: "PUT",
    data: formdata,
  });
}
export const submitSkillGapForm = ({formdata,internship_id}) => {
  return onApiCall({
    url: `company/api/skills-gap-evaluation-form/${internship_id}/`,
    method: "POST",
    data: formdata,
  });
};

export const companySubscription = (org_code, formdata) => {
  return onApiCall({
    url: `company/api/company-subscription/${org_code}`,
    method: "POST",
    data: formdata,
  });
};

export const getCompanySubscription = (org_code) => {
  return onApiCall({
    url: `company/api/company-subscription/${org_code}`,
    method: "GET",
  });
};
export const getNonMigratedIntern = (org_code) => {
  return onApiCall({
    url: `company/api/get-data-migration-interns-count/${org_code}`,
    method: "GET",
  });
};

export const getSelectInternList = (org_code) => {
  return onApiCall({
    url: `company/api/get-list-of-interns-for-data-migration/${org_code}`,
    method: "GET",
  });
};
export const postNewInterns = ({internship_id,formData}) => {
  return onApiCall({
    url: `company/api/selected-interns/${internship_id}/`,
    method: "POST",
    data: formData
  });
};

export const userCompanyInvite = (org_code, formdata) => {
  return onApiCall({
    url: `company/api/company-users-status/${org_code}`,
    method: "PUT",
    data: formdata,
  });
};

export const onAddNewContact = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-create/`,
    method: "POST",
    data: formdata.data,
  });
};

export const viewContactData = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-view/${formdata.id}/${formdata.code}`,
    method: "GET",
    data: formdata.data,
  });
};
export const deleteContactData = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-update/${formdata.code}/${formdata.id}`,
    method: "DELETE",
    data: formdata.data,
  });
};

export const updateContactDataNew = (formdata) => {
  return onApiCall({
    url: `company/api/company-user-update/${formdata.code}/${formdata.id}`,
    method: "PUT",
    data: formdata.data,
  });
};

export const onDeleteLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-delete/${formdata.id}/`,
    method: "DELETE",
    data: formdata.data,
  });
};

export const onViewLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-view/${formdata.id}/`,
    method: "GET",
    data: formdata.data,
  });
};

export const onUpdateLocation = (formdata) => {
  return onApiCall({
    url: `company/api/company-location-update/${formdata.id}/${formdata.code}/`,
    method: "PUT",
    data: formdata.data,
  });
};

export const onUpdateCompanyDatas = (formdata) => {
  return onApiCall({
    url: `company/api/company-update/${formdata.code}/`,
    method: "PUT",
    data: formdata.data,
  });
};

export const onChangePassword = (formdata) => {
  return onApiCall({
    url: `company/api/change-password/`,
    method: "POST",
    data: formdata,
  });
};

export const onStepOneVerification = (formdata) => {
  return onApiCall({
    url: `auth/api/company/register-step-validation/`,
    method: "POST",
    data: formdata,
  });
};

export const registerNewCompany = (formdata) => {
  return onApiCall({
    url: `auth/api/company/addcompany/`,
    method: "POST",
    data: formdata,
  });
};

export const ongetFormOptions = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-form-step-1-scroll-list/`,
    method: "GET",
    data: formdata,
  });
};
export const onSelectCountryandState = (formdata) => {
  return onApiCall({
    url: `/base/api/scroll-list/${formdata.locations}/${formdata.divi}/`,
    method: "GET",
    data: formdata,
  });
};
export const onBoardGetData = (formdata) => {
  return onApiCall({
    url: `base/api/scroll-list/${formdata}/`,
    method: "POST",
    data: { search_key: "" },
  });
};
export const onAboutCountryandStateLIst = (formdata) => {
  return onApiCall({
    url: `base/api/scroll-list/${formdata.locations}/${formdata.divi}/`,
    method: "GET",
    data: formdata,
  });
};
export const getCountries = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-form-step-1-scroll-list/`,
    method: "GET",
    data: formdata,
  });
};

export const getFormOptions = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-form-step-2-scroll-list/`,
    method: "GET",
    data: formdata,
  });
};

export const onOtpVerify = (request) => {
  return onApiCall({
    url: `base/api/email-validation/`,
    method: "POST",
    data: request,
  });
};
export const validateEmailAddress = (request) => {
  return onApiCall({
    url: `base/api/user-validation/`,
    method: "POST",
    data: request,
  });
};
export const onTriggerOtp = (request) => {
  return onApiCall({
    url: `base/api/email-validation-trigger/`,
    method: "POST",
    data: request,
  });
};

export const onUpdatingInternInfo = (request) => {
  return onApiCall({
    url: `company/api/internship-update/${request.code}/${request.id}/`,
    method: "PUT",
    data: request.data,
  });
};

export const jobActionReview = (request) => {
  return onApiCall({
    url: `internship/internship-update-status/`,
    method: "POST",
    data: request,
  });
};

export const onGettingUserInfo = (request) => {
  return onApiCall({
    url: `company/api/company-user-view/${request.code}/`,
    method: "GET",
    data: request,
  });
};

export const onCompanyUserProfileUpdate = (request) => {
  return onApiCall({
    url: `/company/api/company-user-profile-update/`,
    method: "PUT",
    data: request,
  });
};

export const onGettingCompanyInfo = (request) => {
  return onApiCall({
    url: `company/api/company-view/${request.code}/`,
    method: "GET",
    data: request,
  });
};

export const onUpdatingUserInfo = (request) => {
  return onApiCall({
    url: `company/api/company-user-update/${request.code}/`,
    method: "PUT",
    data: request.data,
  });
};

export const getCompanySearchData = (request) => {
  return onApiCall({
    url: `auth/api/company/company-scroll-list/`,
    method: "POST",
    data: request,
  });
};

export const forgotPasswordEmail = (request) => {
  return onApiCall({
    url: `base/api/forgot-password/`,
    method: "POST",
    data: request,
  });
};

export const forgotPasswordValidation = (request) => {
  return onApiCall({
    url: `base/api/forgot-password-validation/`,
    method: "POST",
    data: request,
  });
};

export const resetPassword = (request) => {
  return onApiCall({
    url: `base/api/reset-password/`,
    method: "POST",
    data: request,
  });
};

export const onDeletingInternship = (request) => {
  return onApiCall({
    url: `company/api/internship-delete/${request.code}/${request.id}/`,
    method: "DELETE",
    data: request,
  });
};

export const onUserProfilePicUpdate = (request) => {
  return request?.code
    ? onApiCall({
      url: `company/api/user-picture-update/${request.code}/`,
      method: "PUT",
      data: request.data,
    })
    : onApiCall({
      url: `company/api/user-picture-update`,
      method: "PUT",
      data: request.data,
    });
};

export const onCompanyLogoUpdate = (request) => {
  return onApiCall({
    url: `/company/api/company-picture-update/${request.code}/`,
    method: "PUT",

    data: request.data,
  });
};

export const onValidateDuplicateValues = (request) => {
  return onApiCall({
    url: `base/api/org-validation/${request.code}/`,
    method: "POST",
    data: request.data,
  });
};

export const getAnalyticData = (request) => {
  return onApiCall({
    url: `company/api/dashboard-analytic-data/`,
    method: "GET",
    data: request,
  });
};

// INTERNSHIP COMMON CALLS
export const getInternLists = (formdata) => {
  return onApiCall({
    url: `internship/api/internship-list/`,
    method: "POST",
    data: formdata,
  });
};

export const ongettingInternInfo = (request) => {
  return onApiCall({
    url: `internship/api/internship-view/${request.id}`,
    method: "GET",
    data: request,
  });
};

export const patchStatusDataWithJoiningDate = (request, intern_id) => {
  return onApiCall({
    url: `/internship/internship-apply/${intern_id}`,
    method: "PATCH",
    data: request,
  });
};

export const getApplicantDetails = (id) => {
  return onApiCall({
    url: `/internship/internship-view-applicants/${id}`,
    method: "GET",
  });
};

export const internshipApply = (formdata, id) => {
  return onApiCall({
    url: `/internship/internship-apply/${id}`,
    method: "PATCH",
    data: formdata,
  });
};

export const getUniversitiesMasterList = () => {
  return onApiCall({
    url: `/api/master/universities/`,
    method: "GET",
  });
};

export const getCountriesMasterList = () => {
  return onApiCall({
    url: `/api/master/countries/`,
    method: "GET",
  });
};

export const getSatesMasterList = (country_id) => {
  return onApiCall({
    url: `/api/master/states/${country_id}/`,
    method: "GET",
  });
};

export const getCitiesMasterListByCountry = (country_id) => {
  return onApiCall({
    url: `/api/master/cities-by-country/${country_id}/`,
    method: "GET",
  });
};

export const getCitieMasterList = (country_id, state_id) => {
  return onApiCall({
    url: `/api/master/cities/${country_id}/${state_id}/`,
    method: "GET",
  });
};

export const getOrganisationTypesMasterList = () => {
  return onApiCall({
    url: `/api/master/organisation-types/`,
    method: "GET",
  });
};

export const getSectorMasterList = () => {
  return onApiCall({
    url: `/api/master/sector/`,
    method: "GET",
  });
};

export const getScopeOfStudyMasterList = () => {
  return onApiCall({
    url: `/api/master/scope-of-studies/`,
    method: "GET",
  });
};

export const getSkillMasterList = (searchKey) => {
  return onApiCall({
    url: `/api/master/skills/`,
    method: "GET",
    params: {
      keyword: searchKey,
    },
  });
};

export const getAllSkills = (searchKey) => {
  return onApiCall({
    url: `/api/master/skills`,
    method: "GET",
    params: {
      keyword: searchKey,
    },
  });
};

export const getSkills = (pk) => {
  return onApiCall({
    url: `company/api/skills/${pk}/`,
    method: "GET",
  });
};

export const saveSkills = (pk, formdata) => {
  return onApiCall({
    url: `company/api/skills/${pk}/`,
    method: "POST",
    data: formdata,
  });
};

export const getOrganizationInternshipCoinDetail = (code) => {
  return onApiCall({
    url: `company/api/subscription-internship/${code}/`,
    method: "GET",
  });
};
export const deleteSkills = (id) => {
  return onApiCall({
    url: `company/api/skills-update/${id}`,
    method: "DELETE",
  });
};

export const getjobdocument = (pk) => {
  return onApiCall({
    url: `company/api/jobdocument/${pk}/`,
    method: "GET",
  });
};

export const savejobdocument = (pk, formdata) => {
  return onApiCall({
    url: `company/api/jobdocument/${pk}/`,
    method: "POST",
    data: formdata,
  });
};

export const deletejobdocument = (id) => {
  return onApiCall({
    url: `company/api/jobdocument-update/${id}`,
    method: "DELETE",
  });
};

export const createJobs = (code, formdata) => {
  return onApiCall({
    url: `company/api/jobs/${code}/`,
    method: "POST",
    data: formdata,
  });
};

export const getJobs = (code) => {
  return onApiCall({
    url: `company/api/getjobs/${code}/`,
    method: "GET",
  });
};

export const getlistJobs = (code, peram) => {
  return onApiCall({
    url: `company/api/jobs/${code}/${peram}`,
    method: "GET",
  });
};

export const updateJobs = (formdata) => {
  return onApiCall({
    url: `company/api/jobs-update/`,
    method: "put",
    data: formdata,
  });
};

export const getAllCountries = () => {
  return onApiCall({
    url: `/api/master/countries/`,
    method: "GET",
  });
};

export const getYearOfStudies = () => {
  return onApiCall({
    url: `/api/master/year-of-studies/`,
    method: "GET",
  });
};

export const getStates = (countryId) => {
  return onApiCall({
    url: `/api/master/states/${countryId}`,
    method: "GET",
  });
};
export const getCities = (countryId, stateId) => {
  return onApiCall({
    url: `/api/master/cities/${countryId}/${stateId}/`,
    method: "GET",
  });
};

export const saveLocation = (pk, formdata) => {
  return onApiCall({
    url: `company/api/location/${pk}`,
    method: "POST",
    data: formdata,
  });
};

export const getLocations = (pk) => {
  return onApiCall({
    url: `company/api/location/${pk}`,
    method: "GET",
  });
};

export const updateLocation = (id, formdata) => {
  return onApiCall({
    url: `company/api/location-update/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const deleteLocation = (id) => {
  return onApiCall({
    url: `company/api/location-update/${id}`,
    method: "DELETE",
  });
};

export const getCompanyBranding = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-view/${formdata.code}/`,
    method: "GET",
  });
};

export const updateCompanyBranding = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-update/${formdata.code}/${formdata.id}/`,
    method: "PUT",
    data: {
      primary_color: formdata.primary_color,
      secondary_color: formdata.secondary_color,
      video_url: formdata.video_url,
      about: formdata.about,
      video_description: formdata.video_description,
      code: formdata.code,
    },
  });
};

export const createCompanyBranding = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-create/`,
    method: "POST",
    data: {
      primary_color: formdata.primary_color,
      secondary_color: formdata.secondary_color,
      video_url: formdata.video_url,
      about: formdata.about,
      video_description: formdata.video_description,
      code: formdata.code,
    },
  });
};

export const getCompanyBrandingImages = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-image-view/${formdata.code}/`,
    method: "GET",
  });
};

export const getCompanyBrandingArticles = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-article-view/${formdata.code}/`,
    method: "GET",
  });
};

export const onCompanyBrandingImageUpdate = (request) => {
  return onApiCall({
    url: `/company/api/company-branding-image-create/${request.code}/`,
    method: "POST",
    data: request.data,
  });
};

export const onCompanyBrandingArticleUpdate = (request) => {
  return onApiCall({
    url: `/company/api/company-branding-article-create/${request.code}/`,
    method: "POST",
    data: request.data,
  });
};

export const onCompanyBrandingImageDelete = (request) => {
  return onApiCall({
    url: `/company/api/company-branding-image-delete/${request.code}/${request.id}/`,
    method: "DELETE",
  });
};

export const onCompanyBrandingArticleDelete = (request) => {
  return onApiCall({
    url: `/company/api/company-branding-article-delete/${request.code}/${request.id}/`,
    method: "DELETE",
  });
};

export const getTitleList = (lan, keyword, gettype) => {
  return onApiCall({
    url: `company/api/jobstitlelist/${lan}/`,
    method: "POST",
    data: { keyword: keyword, gettype: gettype },
  });
};

export const paymentGateWay = (body) => {
  const token = "JV7rf40EXU56eHZDyFcp7zNn2Xpe0bl24dgC08CB";
  return axios.post(
    `${process.env.REACT_APP_CENTRAL_URL}api/subscription`,
    body,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
export const viewapplicationList = (data) => {
  return onApiCall({
    url: `company/api/viewapplications/`,
    method: "POST",
    data: data,
  });
};
export const getCounts = () => {
  return onApiCall({
    url: `company/api/count-details-view/123`,
    method: "GET",
  });
};

export const getApplications = (code) => {
  return onApiCall({
    url: `company/api/getapplications/${code}/`,
    method: "GET",
  });
};

export const getTalentSearhUser = (code) => {
  return onApiCall({
    url: `/company/api/talent-search-save-profile/${code}/`,
    method: "GET",
  });
};

export const addTalentSearchUser = (code, formdata) => {
  return onApiCall({
    url: `/company/api/talent-search-save-profile/${code}/`,
    method: "POST",
    data: formdata,
  });
};


export const changApplications = (path, id, data) => {
  return onApiCall({
    url: `company/api/changeapplication/${path}/${id}/`,
    method: "POST",
    data: data,
  });
};

export const getjobslist = (code) => {
  return onApiCall({
    url: `company/api/getjobslist/${code}/`,
    method: "GET",
  });
};

export const getQuota = (org_code) => {
  return onApiCall({
    url: `company/api/company-getquota/${org_code}`,
    method: "GET",
  });
};

export const forceChangePassword = (
  uid,
  token,
  newPassword,
  confirmNewPassword,
  email
) => {
  return onApiCall({
    url: "company/api/force-change-password/",
    method: "POST",
    data: {
      uid: uid,
      token: token,
      new_password: newPassword,
      confirm_password: confirmNewPassword,
      email: email,
    },
  });
};

export const talentSearch = (formdata, peram) => {
  return onApiCall({
    url: `company/api/talent-search${peram}`,
    method: "POST",
    data: formdata,
  });
};
export const getTalentOceanReport = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/ocean-report/${param}/`,
    method: "GET",
  });
};
export const getDataWorkValues = (param) => {
  return onApiCall({
    url: `api/dashboard/coaching/assessment-results/work-values/${param}`,
    method: "GET",
  });
};
export const getDataWorkInterest = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/interest-riasec/${param}`,
    method: "GET",
  });
};
export const getDataWorkPersonality = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/five-factor/${param}`,
    method: "GET",
  });
};
export const getDataWorkEmployability = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/employability/${param}`,
    method: "GET",
  });
};

export const getDataWorkFutureWorks = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/century-skills/${param}`,
    method: "GET",
  });
};

export const getDataWorkEnglishTest = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/english-test/${param}`,
    method: "GET",
  });
};

export const getTalentData = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/interest-riasec/${param}`,
    method: "GET",
  });
};
export const getMyCyOverall = (param) => {
  return onApiCall({
    url: `/mycv/overall-mycv-company/${param}/`,
    method: "GET",
  });
};
export const getOverViewProfile = (param) => {
  return onApiCall({
    url: `/mycv/overview-company/${param}/`,
    method: "GET",
  });
};

export const updateTalentSearchToken = (param) => {
  return onApiCall({
    url: `company/api/update-talent-search-token/`,
    method: "POST",
    data: param
  });
};

export const getDashboardData = () => {
  return onApiCall({
    url: `/api/dashboard/coaching/company/results`,
    method: "GET",
  });
};
export const getAppliedJobDocuments = (formdata) => {
  return onApiCall({
    url: `company/api/job-documents-view/${formdata.job_id}/?user_id=${formdata.user_id}`,
    method: "GET",
  });
};

export const getGenderRatio = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `api/dashboard/company/analytical/demographic/total-students-by-gender/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getGenderPoputation = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `api/dashboard/company/analytical/demographic/total-employees-by-registration-status/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getPersonalityProfileUser = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `api/dashboard/company/analytical/personality/user/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getQuizzesCompletion = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `api/dashboard/company/analytical/quizzes/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getPersonalitySummary = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `api/dashboard/company/analytical/personality/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,

  });
};

export const gettalentSearchScroll = (formdata, peram) => {
  return onApiCall({
    url: `company/api/talent-search-scroll${peram}`,
    method: "POST",
    data: formdata,
  });
};
export const getUpdateDate = ({formdata, peram}) => {
  return onApiCall({
    url: `/company/api/interns-for-advertised-internship-edit-date/${peram}/`,
    method: "PUT",
    data: formdata,
  });
};

export const addapplications = (jobid, user_id) => {
  return onApiCall({
    url: `company/api/addapplications/${jobid}/${user_id}`,
    method: "GET",
  });
};

export const updateViewprofile = (formdata) => {
  return onApiCall({
    url: `company/api/talent-profile-counts`,
    method: "POST",
    data: formdata,
  });
};
export const addShortlistTalentSearch = (formdata) => {
  return onApiCall({
    url: `/company/api/shortlist-from-talent-search/`,
    method: "POST",
    data: formdata,
  });
};
export const getPersonalityAndMotivationDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `api/dashboard/company/analytical/five-factor/6/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getDrilldown = (url, { filters, chartsLoadingController }) => {
  return onApiCall({
    url,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const postInternshipModule = (formdata) => {
  return onApiCall({
    url: `company/api/internship-modules/create/`,
    method: "POST",
    data: formdata,
  });
};

export const getQuestionAnswers = ()=>{
  return onApiCall({
    url: `company/api/questions-and-answers/`,
    method: "GET",
  });
}

export const getAnnualSurveyData = (organization_id)=>{
  return onApiCall({
    url: `/api/master/v1/annual-survey-questions/`
  })
}


export const postAnnulaSurvey = (formdata,organization_id) => {
  return onApiCall({
    url: `company/api/annual-survey/${organization_id}/`,
    method: "POST",
    data: formdata,
  });
};


export const getInternshipModuleList = (org_code) =>{
return onApiCall({
  url: `company/api/${org_code}/internship-modules/`,
  method: "GET"
})
}

//Handle adding intern details for direct hiring
export const addNewIntern = (formData,internship_id)=>{
  return onApiCall({
    url:`company/api/interns-for-mysip-direct-hiring/${internship_id}/`,
    method: "POST",
    data: formData
  })
}

export const internBulkUploadValidate = (formData)=>{
  return onApiCall({
    url:`company/api/validate-import-intern-for-my-sip-direct-hiring/`,
    method:"POST",
    data: formData
  })
}
export const singleInternBulkUploadValidate = (formData)=>{
  return onApiCall({
    url:`company/api/validate-import-intern-for-my-sip-direct-hiring/`,
    method:"PUT",
    data: formData
  })
}
export const validateAddIntern = (formData) =>{
  return onApiCall({
    url: `company/api/validate-without-internshipid-interns-for-mysip-direct-hiring/`,
    method: "POST",
    data: formData
  })
}

export const internBulkUpload = (formData,internship_id)=>{
  return onApiCall({
    url:`company/api/import-interns-for-mysip-direct-hiring/${internship_id}/`,
    method:"POST",
    data: formData
  })
}
export const getfromDetails = (organization_id)=>{
  return onApiCall({
    url: `/company/api/annual-survey/${organization_id}/`,
    method:"GET",
  })
}

export const getInternBulkExcelTemplate =()=>{
  return onApiCall({
    url:`company/api/interns-for-mysip-direct-hiring-excel-template-export/`,
    method:"GET",
  })
}
export const getLOEHistory = (org_code) =>{
  return onApiCall({
    url: `company/api/${org_code}/loe-applications/history/`,
    method: "GET"
  })
}

export const getLOEListSubmission = (org_code) =>{
  return onApiCall({
    url: `company/api/${org_code}/loe-applications/`,
    method: "GET"
  })
}

export const postLOESubmission = (org_code, formData) =>{
  return onApiCall({
    url: `company/api/${org_code}/loe-applications/submit`,
    method: "POST",
    data: formData
  })
}

export const getInternshipDetails = (org_code, id) =>{
  return onApiCall({
    url: `company/api/internship-view/${org_code}/${id}`,
    method: "GET"
  })
}

export const getInternDetails = (internship_id) =>{
return onApiCall({
  url: `company/api/interns-for-mysip-direct-hiring/${internship_id}/?page_size=3000`,
  method: "GET"
})
}

export const getAdvertisedInternDetails = (internship_id) =>{
  return onApiCall({
    url: `company/api/interns-for-advertised-internship/${internship_id}/?page_size=3000`,
    method: "GET"
  })
  }


export const getAcademicQualificationList = () =>{
  return onApiCall({
    url: `/api/master/academic-qualifications/`,
    method: "GET"
  })
}

export const postCompletionform = (internship_id,formData) =>{
  return onApiCall({
    url:`company/api/internship-completion-form/${internship_id}/`,
    method:"POST",
    data:formData
  })
}

export const getCompletionForm = (internship_id)=>{
  return onApiCall({
    url:`/company/api/internship-completion-form/${internship_id}/`,
    method:"GET",
  })
}

export const getCompletionAdditionalAllowance = ()=>{
  return onApiCall({
    url:`company/api/additional-allowance-for-internship-completion-form/`,
    method:"GET"
  })
}

export const getCompletionLearningOutcomes =()=>{
  return onApiCall({
    url:`company/api/learning-outcome-for-internship-completion-form/`,
    method:"GET"
  })
}

export const getCompletionTraningProviderType=()=>{
  return onApiCall({
    url:`company/api/type-of-training-provided-for-internship-completion-form/`,
    method:"GET"
  })
}

export const getInternshipModuleByID = (id) =>{
return onApiCall({
  url: `company/api/internship-modules/${id}/`,
  method: "GET"
})
}

export const editEligibility = (internship_module_id, formdata) =>{
  return onApiCall({
    url: `company/api/internship-modules/update/${internship_module_id}/`,
    method: "PUT",
    data: formdata,
  })
}

export const fetchTechnicalSkills = () =>{
  return onApiCall({
    url: `company/api/technical-skills/`,
    method: "GET"
  })
}

export const fetchSoftSkills = () =>{
  return onApiCall({
    url: `company/api/soft-skills/`,
    method: "GET"
  })
}

export const getComments = (internship_module_id) =>{
  return onApiCall({
    url: `company/api/internship-module-comments/${internship_module_id}/`,
    method: "GET"
  })
}

export const getSectors = ()=>{
  return onApiCall({
    url:`/api/master/sector/`,
    method:"GET"
  })
}

export const sendSingleReminder=(internship_id,formdata)=>{
  return onApiCall({
    url:`company/api/send-single-reminder-to-intern/${internship_id}/`,
    method:"POST",
    data: formdata
  })
}

export const sendMultipleReminder=(internship_id,formdata)=>{
  return onApiCall({
    url:`company/api/send-multiple-reminder-to-interns/${internship_id}/`,
    method:"POST",
    data: formdata
  })
}

export const getInternlistDownload=(internship_id)=>{
  return onApiCall({
    url:`company/api/download-interns-list/${internship_id}/`,
    method:"GET"
  })
}

export const getCompletionFormComments=(id)=>{
  return onApiCall({
    url: `company/api/internship-completion-form-comments/${id}/`,
    method: "GET"
  })
}

export const updateCompletionForm = (id, formData) =>{
  return onApiCall({
    url: `company/api/internship-completion-form-update/${id}/`,
    method: "PUT",
    data: formData
  })
}

export const retrieveInternshipModuleByOrg = (org_code) =>{
  return onApiCall({
    url: `company/api/${org_code}/internship-module-dashboard/`,
    method: "GET",
  })
}

export const CheckToken= (internship_id)=>{
  return onApiCall({
    url: `company/api/mysip-token-deduction/${internship_id}/`,
    method: "POST",
  })
}