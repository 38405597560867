import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import InterShipCard from "../../components/interShipCard/interShipCard";
import InternIntroSection from "../../components/internIntoSection/internIntroSection";
import InternShipDetails from "../../components/internShipDetails/internShipDetails";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ongettingInternInfo,
  getApplicantDetails,
  jobAddNotes,
} from "../../services/apiCalls";
import Loader from "../../components/loader/loader";

import AlertModalReview from "../../components/alertModalNew/alertModalReview";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import Button from "../../components/button/button";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import TablePagination from '@mui/material/TablePagination';
import { toast } from "react-toastify";



const SingleField = styled.div`
  margin-bottom: 30px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const FormWrapper = styled.div`
  margin: 25px 0px;
  padding: 25px 70px 29px 63px;
  background: #ffffff;
  border-radius: 10px;
  @media (max-width: 767px) {
    padding: 25px 8px 29px 8px;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0px;
    color: ${Colors.labelText};
  }
  > p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
  > p2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);

export default function ViewDetails() {
  const { t, } = useTranslation();
  const schema2 = yup.object().shape({
    notes: yup.string().required("Message is a required field."),
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2, // values: values2 
    },
  } = useForm({
    resolver: yupResolver(schema2),
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [theArray, setTheArray] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const [internData, setInternData] = useState([]);
  const [applicantData, setApplicantData] = useState([]);
  const [idInternShip,] = useState("");
  const [showConfirmationModalAddNotes, setShowConfirmationModalAddNotes] =
    useState(false);

  const org = useSelector((state) => state.org.orgData);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [app_id,] = useState("");
  const navigate = useNavigate();

  const code = org?.additional_info?.org_code;
  const emptyRowsAdd =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;
  useEffect(() => {
    const fetchDatas = async () => {
      await getInternData();
      await getApplicantData();
    }
    fetchDatas();
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const getApplicantData = async () => {
    const { data } = await getApplicantDetails(id);
    if (data && data.status === 1) {
      setApplicantData(data?.applicants);
    }
    setLoading(false);
    data?.applicants.map((app) => {
      var arr = app?.internship_notes ?? [];
      setTheArray(arr);
      return arr;
    });
  };

  const content3 = () => {
    return (
      <>
        {/* <Message>Ardsfsp ?</Message> */}
        <form onSubmit={handleSubmit2(onSubmit2)}>
          <div className="container">
            <SingleField>
              <FormLabel>{t('Add Notes')}</FormLabel>
              <Controller
                name="notes"
                control={control2}
                render={({ field }) => (
                  <TextArea
                    style={{ background: "#F3F3F3" }}
                    {...field}
                    rows="6"
                    placeholder={t("Enter Note")}
                  />
                )}
              />
              {errors2.notes && <ErrorField err={t(errors2.notes.message)} />}
            </SingleField>
            <Button
              width="100px"
              name={t("Save")}
              className="mt-0 mb-5"
              type="submit"
              style={{ float: "right" }}
            />
          </div>
        </form>
        <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t('Notes')}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t('Created')}
                  </th>
                </tr>
              </thead>
              <tbody>
                
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                    ? theArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : theArray
                  ).map((row) => (
                    <>
                      {row.intern_user === app_id ? (
                        <tr key={row.notes}>
                          <td>{row.notes}</td>
                          <td style={{ width: 200 }} align="right">
                            {moment(new Date(row.created_at)).format(
                              "DD/MM/YYYY hh:mm:ssA"
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  ))
                  : ""}
                {emptyRowsAdd > 0 && (
                  <tr style={{ height: 41 * emptyRowsAdd }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              {theArray?.length > 0 ? (
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        30,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={theArray?.length ?? "0"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      componentsProps={{
                        select: {
                          "aria-label": t("rows per page"),
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tr>
                </tfoot>
              ) : (
                <div className="container col-lg-12">{t('No Comments')}</div>
              )}
            </table>
          </Root>
        </div>
      </>
    );
  };

  const onSubmit2 = async (data) => {
    const body = {
      internship: idInternShip.apply_internship,
      notes: data.notes,
      intern_user: idInternShip.apply_user,
    };

    // await setStatus(true);
    const result = await jobAddNotes(body);
    if (result.status === 200) {
      // await setShow(false);
      if (result.data.message === "success") {
        setShowConfirmationModalAddNotes(false);
        // await setStatus(false);
        body.notes = "";
        data.notes = "";
        await toast.success(t("Notes added successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        // getInternData();
        getApplicantData();
        setValue2("notes", "");
        // setValue('comments', ' ');
      }
    }
  };
  const getInternData = async () => {
    setLoading(true);
    const { data } = await ongettingInternInfo({ id: id, code: code });
    if (data && data.status === 1) {
      setInternData(data?.data);
    } else {
      setLoading(false);
    }
  };
  const closeConfirmationModalAddNotes = () => {
    setShowConfirmationModalAddNotes(false);
  };


  const setShowApplicantStatus = () => {
    navigate("/internships/applicants?id=" + id);
    //setShowApplicants(true);
  };



  return (
    <MainLayout>
      {/* <HamburgerLayout>
        <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
      </HamburgerLayout>
      {isOpenham && <Menus />} */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div role="presentation">
            <div
              className="d-flex mb-3"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
              >
                {t('Internships')} &nbsp; &#62;
              </Link>
              <div className="mx-2" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t('Internship Detail')}
              </span>
            </div>
          </div>
          <FormWrapper>
            <InternIntroSection data={internData} />
            <InterShipCard data={internData} />
            <InternShipDetails
              data={internData}
              setShowApplicants={setShowApplicantStatus}
              noOfApplicants={applicantData.length}
            />
          </FormWrapper>
        </>
      )}
      <AlertModalReview
        show={showConfirmationModalAddNotes}
        close={closeConfirmationModalAddNotes}
        content={content3()}
        id={idInternShip}
      />
    </MainLayout>
  );
}
