import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { schema } from "../newIntern/staticContent";
import * as Mui from "@material-ui/core";
import CustomButton from "../../components/button/button";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import ViewProfile from "../applicants/ViewProfile";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ErrorField from "../../components/errorField/errorField";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { formatDate } from "../../utilities/commonFunctions";
import { internshipApply, getApplicantDetails } from "../../services/apiCalls";
import { toast } from "react-toastify";
import AlertModal from "../../components/alertModal/alertModal";
import Button from "../../components/button/button";
import { useTranslation } from "react-i18next";



const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const FormGroup = styled.div`
  width: 100%;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const Message = styled.div`
  font-size: 22px;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const OtherText = styled.p`
  margin: 0 0 0 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;

const useStyles = Mui.makeStyles((theme) => ({
  avatarStyle: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const JobsWrapper = styled.span`
  margin: 0;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
  cursor: pointer;
`;

const ResumeName = styled.div`
  font-size: 1em;
  font-family: "General Sans";
  font-weight: 500;
  text-transform: capitalize;
`;

const SubHeaderName = styled.div`
  font-size: 0.75em;
  font-family: "General Sans";
  font-weight: 500;
  color: #656565;
`;

const GridWrapper = styled.div`
  display: grid;
  margin-bottom: 30px;
`;

const AcademicGridWrapper = styled(GridWrapper)`
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  padding-top: 15px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  label {
    position: relative;
  }
`;

const IconSingleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;

const StatusText = styled.p`
  margin-left:15px;
  text-transform: capitalize;
  font-weight:500;
  color: ${(props) =>
    props.status === "offered" ? Colors.successText : Colors.errorText};
`;

const DateText = styled.span`
  margin-left:15px;
  text-transform: capitalize;
  font-weight:500;
  // color: #D04C8D;
`;

export default function Applicants(props) {
  const navigate = useNavigate();
  const { t, } = useTranslation();

  const handleApproveAddNotes = props.handleApproveAddNotes;
  const [show, setShow] = useState(false);
  const internFields = useSelector((state) => state.intern.value);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  // const code = org?.additional_info?.org_code;
  const [applicantFiles, setApplicantFiles] = useState(null);
  const {
    control,
    // handleSubmit,
    // setValue,
    // watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...internFields },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [profileModal, setProfileModal] = useState(false);
  const classes = useStyles();
  const {
    display_name,
    // first_name,
    scope_abbr,
    curr_qualification_abbr,
    profile_pic,
    applied_user_id,
    offered_date,
    hired_date,
    updated_at,
    joining_date
  } = props.data;

  console.log("propsData",props.data)
  const { totalVacancy, selectedProfiles, setSelectedProfiles } = props;

  const [joiningDate, setJoiningDate] = useState(
    // props?.joiningDate ? props?.joiningDate : ""
    props?.data?.joining_date ? props?.data?.joining_date : ""
  );
  const [leavingDate, setLeavingDate] = useState(
    props?.data?.leaving_date ? props?.data?.leaving_date : ""
  );

  const [updateTime, setUpdateTime] = useState("");
  const [status, setStatus] = useState(props?.data?.talent_intern_status);
  const [currentAction, setCurrentAction] = useState(null);
  const [actionMessage, setActionMessage] = useState("");

  const onCloseModal = () => {
    setProfileModal(false);
  };

  const viewProfileClick = () => {
    setProfileModal(true);
  };

 
  const viewAssessmentsClick = (applied_user_id) => {
    let path = `/internships/details/viewAssessment/${applied_user_id}?id=${id}`;
    navigate(path);
  };

  const closeModal = () => {
    setShow(false);
  };

  const dateChange = (val, name) => {
    if (name === "joiningDate") {
      setJoiningDate(formatDate(val));
    } else if (name === "leavingDate") {
      setLeavingDate(formatDate(val));
    }
  };

  useEffect(() => {
    getApplicantData();
    setUpdateTime(props.data.update_time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getApplicantData = async () => {
    const { data } = await getApplicantDetails(id);
    if (data && data.status === 1) {
      const applicantDocument = data["applicants"][0]["user_documents"];
      const filesArr = [
        "cover_path",
        "resume_path",
        "transcript",
        "writing_sample_path",
        "other_docs"
      ];
      const tempApplicantFiles = {};
      filesArr.forEach((item) => {
        if (
          applicantDocument[item] !== "" &&
          applicantDocument[item] !== "/storage/False"
        ) {
          tempApplicantFiles[item] = applicantDocument[item];
        }
      });
      setApplicantFiles(tempApplicantFiles);
    }
  };

  const onConfirmation = async () => {
    const action = currentAction;
    const { data } = await internshipApply(
      {
        status: action,
        applied_user_id,
        joiningDate,
        leavingDate,
        update_time: updateTime,
      },
      props?.id
    );
    if (data && data.status === 1) {
      setUpdateTime(data.data.update_time);
      let message = "";
      if (action === "offered") {
        message = t("Internship approved successfully");
        setSelectedProfiles(selectedProfiles + 1);
      } else if (action === "rejected") {
        message = t("Internship rejected successfully");
      }
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setStatus(action);
    } else {
      toast.error(t("Internship not saved"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setShow(false);
  };

  const approveOrRejectApplicant = async (action, dataN) => {
    setCurrentAction(action);
    if (action === "applied") {
      const { data } = await internshipApply(
        {
          status: action,
          applied_user_id,
          joiningDate,
          leavingDate,
          update_time: updateTime,
        },
        props?.id
      );
      if (data && data.status === 1) {
        setUpdateTime(data.data.update_time);
        toast.success(t("Internship recalled successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setStatus(action);
        setSelectedProfiles(selectedProfiles - 1);
      } else {
        toast.error(t("Internship not saved"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (action === "rejected") {
        setActionMessage(t("Are you sure you want to reject this application?"));
        setShow(true);
      } else {
        if (selectedProfiles < totalVacancy) {
          setActionMessage(t("Are you sure you want to approve this application?"));
          setShow(true);
        } else {
          toast.error(t("Internship positions fullfilled"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
  };
  const content = () => {
    return (
      <div>
        <Message>{actionMessage}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="20px 0px 30px 0px"
              name={t("Yes")}
              onClick={() => onConfirmation()}
            />
            <CustomButton
              secondary
              width="8em"
              name={t("No")}
              fontSize="18px"
              lineHeight="24px"
              minWidth="0"
              margin="20px 0px 30px 20px"
              onClick={closeModal}
            />
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <>
      {profileModal && (
        <ViewProfile
          id="viewProfile"
          open={profileModal}
          onClose={onCloseModal}
          data={props.data}
        />
      )}
      <Row>
        <Col sm={12} md={3} xd={4}>
          <div style={{ display: "flex" }}>
            <Mui.Avatar src={profile_pic} className={classes.avatarStyle} />
            <div
              style={{
                margin: "auto 10px",
                minWidth: "70%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ResumeName>{display_name}</ResumeName>
              <SubHeaderName>
                {scope_abbr} | {curr_qualification_abbr}
              </SubHeaderName>
              <JobsWrapper
                onClick={viewProfileClick}
                style={{ marginLeft: '5px' }}
              >{t('View Profile')}
              </JobsWrapper>
              <JobsWrapper
                onClick={() => viewAssessmentsClick(applied_user_id)}
                style={{ marginLeft: '5px' }}
              >{t('View Assessments')}
              </JobsWrapper>
              {applicantFiles &&
                Object.keys(applicantFiles).map((file_path, index) => {
                  return (
                    <IconSingleSection key={file_path + index}>
                      <OtherText>
                        
                        <a
                          target="_blank" rel="noreferrer"
                          href={props.data.user_documents[file_path]}
                          download
                        >
                          {/* Click to download */}
                          <JobsWrapper style={{ margin: 0 }}>
                            {file_path === "cover_path" &&
                              applicantFiles.cover_path !== null
                              ? t("Cover letter")
                              : ""}
                            {file_path === "resume_path" &&
                              applicantFiles.resume_path !== null
                              ? "CV"
                              : ""}
                            {file_path === "transcript" &&
                              applicantFiles.transcript !== null
                              ? t("Transcript")
                              : ""}
                            {file_path === "writing_sample_path" &&
                              applicantFiles.writing_sample_path !== null
                              ? t("Writing sample")
                              : ""}
                            {file_path === "other_docs" &&
                              applicantFiles.other_docs !== null
                              ? t("Optional Document")
                              : ""}
                          </JobsWrapper>
                        </a>
                        {/* </AttachedDocument> */}
                      </OtherText>
                    </IconSingleSection>
                  );
                })}
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} xd={3}>
          <AcademicGridWrapper template="1fr 1fr">
            <FormGroup>
              <Controller
                name="joining_date"
                control={control}
                render={({ field }) => (
                  <label style={{ width: "inherit" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="dd/mm/yyyy"
                          views={["year", "month", "date"]}
                          // value={joiningDate}
                          disabled={status !== "applied"}
                          onChange={(val) => dateChange(val, "joiningDate")}
                          fullWidth
                          value={joiningDate ? new Date(joiningDate) : null}
                          style={{ marginBottom: '-40px' }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                  </label>
                )}
              />
              {errors.joining_date && (
                <ErrorField err={t(errors.joining_date.message)} />
              )}
            </FormGroup>
          </AcademicGridWrapper>
        </Col>
        <Col sm={12} md={3} xd={3}>
          <AcademicGridWrapper template="1fr 1fr">
            <FormGroup>
              <Controller
                name="leaving_date"
                control={control}
                render={({ field }) => (
                  <label style={{ width: "inherit" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="dd/mm/yyyy"
                          views={["year", "month", "date"]}
                          // value={leavingDate}
                          disabled={status !== "applied"}
                          onChange={(val) => dateChange(val, "leavingDate")}
                          fullWidth
                          minDate={
                            joiningDate
                              ? new Date(joiningDate).setDate(
                                new Date(joiningDate).getDate() + 1
                              )
                              : new Date().setDate(new Date().getDate() + 1)
                          }
                          value={leavingDate ? new Date(leavingDate) : null}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                  </label>
                )}
              />
              {errors.joining_date && (
                <ErrorField err={t(errors.joining_date.message)} />
              )}
            </FormGroup>
          </AcademicGridWrapper>
        </Col>
        <Col sm={12} md={2} xd={2}>
          {status !== "applied" ? (
            <Row className="d-flex align-items-baseline">
              {/* have to use media query - to adjust this text */}
              <Col className="mr-1 " xs={12}>
{
  status === "offered"
  ?<>
  <StatusText status={status}>{t(status)}</StatusText>
  <div style={{width:"110%"}}>
  <DateText>Offered Date:</DateText>
  <DateText style={{color:"#D04C8D"}}>{offered_date ?? updated_at}</DateText>
  </div>  
  <div style={{width:"110%"}}>
  <DateText>Hired Date:</DateText>
  <DateText style={{color:"#D04C8D"}}>{hired_date ?? joiningDate}</DateText>
  </div>
  </>
  
  :                 <StatusText status={status}>{t(status)}</StatusText>

}
              </Col>
              <Col xs={12}>
                <CustomButton
                  secondary
                  width="8em"
                  name={t("Recall")}
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  onClick={() =>
                    approveOrRejectApplicant("applied", props.data)
                  }
                />
              </Col>
            </Row>
          ) : (
            <Row>

              <Col xs={12}>
                <CustomButton
                  margin="15px 10px 15px 0px"
                  width="8em"
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  name={t("Approve")}
                  onClick={() =>
                    approveOrRejectApplicant("offered", props.data)
                  }
                />
              </Col>
              <Col>
                <CustomButton
                  secondary
                  width="8em"
                  name={t("Deny")}
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  onClick={() =>
                    approveOrRejectApplicant("rejected", props.data)
                  }
                />
              </Col>
            </Row>
          )}


          <Col sm={12} md={4} xd={1}>
            {/* <ButtonWrapper> */}
            <Button
              // width="100%"
              style={{ width: "61px" }}
              name="+"
              onClick={(e) => {
                handleApproveAddNotes(e);
                props.handleIdNotes(e, props.data);
              }}
            />
            {/* </ButtonWrapper> */}
          </Col>
        </Col>
      </Row>
      {/* <Row>
       
      </Row> */}
      <AlertModal show={show} close={closeModal} content={content()} />
      {/* <InternShipDetails data={internData}
            setShowApplicants={setShowApplicantStatus}
            noOfApplicants={applicantData.length}/> */}
    </>
  );
}
